import React, { useEffect, useState } from 'react';
import OtpInputWithValidation from './components/OtpInputwithValidation';
import { useNavigate } from 'react-router-dom';
import { sendOTPEmailAPI } from '../../api/userAPI';

/**
 * Renders the OTP Page.
 * @returns {React.JSX.Element} The rendered OTP Page.
 */
export default function OTP() {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const storedEmail = localStorage.getItem('email');

        if (!storedEmail) {
            navigate('/login');
        }
        setEmail(storedEmail);
    }, []);

    const sendOTPEmail = async () => {
        //TODO: ensure this is rate limited and have ui prevent multiple submissions
        setIsSubmitting(true);
        try {
            await sendOTPEmailAPI(email.toLowerCase());
        } catch (err) {
            setError(err.message || 'Unable to send code. Try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <div className="mx-auto -mt-20 flex min-h-screen max-w-2xl flex-col items-center justify-center gap-10 overflow-auto text-center">
            <div>
                <h1 className="medium-title tracking-wide md:text-4xl">
                    Verification Code Sent
                </h1>
                <p className=" mt-2 tracking-wide md:text-lg">
                    We sent a verification code to the email associated with
                    your account. Please enter the code to proceed.
                </p>
            </div>

            <OtpInputWithValidation numberOfDigits={6} email={email} />
            {error && <p className="mt-10 error-text">{error}</p>}

            <div className="mt-10 flex items-center justify-center gap-1">
                <p className="tracking-wide md:text-lg">
                    Didn&apos;t receive a code?
                </p>
                <button
                    className="link"
                    type="button"
                    onClick={sendOTPEmail}
                    disabled={isSubmitting}
                >
                    Re-send Verification Code
                </button>
            </div>
        </div>
    );
}
