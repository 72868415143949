import React from 'react';
import { useFormContext } from 'react-hook-form';

const reasonsForInterest = [
    {
        name: 'My autoimmune condition',
        icon: 'path/to/autoimmune-condition-icon.svg',
    },
    {
        name: 'Help a love one with autoimmunity',
        icon: 'path/to/loved-one-condition-icon.svg',
    },
    {
        name: 'Chronic issue',
        icon: 'path/to/chronic-issue-icon.svg',
    },
    {
        name: 'Looking for better treatments',
        icon: 'path/to/better-treatments-icon.svg',
    },
    {
        name: 'Help cure autoimmunity',
        icon: 'path/to/cure-autoimmunity-icon.svg',
    },
    {
        name: 'Receive rewards for learning',
        icon: 'path/to/rewards-learning-icon.svg',
    },
    {
        name: 'Contribute data for rewards',
        icon: 'path/to/rewards-contributing-icon.svg',
    },
];

/**
 * Renders the Interest component.
 * @returns {React.JSX.Element} The rendered Interest component.
 */
export default function Interest() {
    const { register, watch } = useFormContext();
    const selectedInterest = watch('reasonForUsingApp');
    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2">
                <div className="flex flex-col items-center gap-2 text-center ">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl">
                        Any reason you want to use BlueFairy?
                    </h1>
                    <h2>Choose one from the following</h2>
                </div>
            </div>
            <div className="mt-10 grid grid-cols-2 gap-4 px-4 md:mt-20">
                {reasonsForInterest.map((item, index) => (
                    <label
                        key={index}
                        className={`h-24 rounded-lg border px-8 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:h-20 md:text-lg ${
                            selectedInterest === item.name
                                ? 'bg-pictonBlue-300'
                                : ''
                        }`}
                    >
                        {item.name}
                        <input
                            type="radio"
                            value={item.name}
                            {...register('reasonForUsingApp')}
                            className="hidden" // Hide the radio but keep it functional
                        />
                    </label>
                ))}
            </div>
        </>
    );
}
