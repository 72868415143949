import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitSymptomLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Symptom Log',
    description:
        'Track symptoms to identify patterns and potential health issues.',
    fields: [
        {
            name: 'date',
            label: 'Date of Symptom',
            type: 'date',
            required: true,
        },
        {
            name: 'symptom',
            label: 'Symptom Experienced',
            type: 'text',
            required: true,
        },
        {
            name: 'severity',
            label: 'Severity of Symptom',
            type: 'slider',
            min: 0,
            max: 10,
            required: true,
            description:
                'Rate the severity of the symptom from 1 (mild) to 10 (severe).',
        },
        {
            name: 'frequency',
            label: 'Frequency of Occurrence',
            type: 'number',
            required: true,
            description:
                'How often the symptom occurs (number of times per day).',
        },
        {
            name: 'associatedConditions',
            label: 'Associated Conditions',
            type: 'multiSelect',
            options: [
                { value: 'allergy', label: 'Allergy' },
                { value: 'cold', label: 'Cold' },
                { value: 'flu', label: 'Flu' },
                { value: 'stress-related', label: 'Stress-related' },
                { value: 'chronic illness', label: 'Chronic illness' },
                { value: 'other', label: 'Other' },
            ],
            required: true,
        },
    ],
};

/**
 * SymptomLog component renders a dynamic form to log symptoms experienced.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function SymptomLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitSymptomLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'An error occurred while submitting the symptom log. Please try again.',
                status: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
        />
    );
}
