import React from 'react';
import FeatureRequestModal from './components/FeatureRequestModal';
import ShopItem from './components/ShopItem';
import SaladIcon from '../../assets/icons/salad.svg';
import PartyIcon from '../../assets/icons/party.svg';

const rewards = [
    {
        title: '1-on-1 Nutritional Coaching',
        description: 'Get personalized nutritional advice from our experts.',
        price: 1000,
        icon: PartyIcon,
    },
    {
        title: 'Anti-inflammatory Recipes',
        description:
            'Discover delicious and healthy recipes for managing inflammation.',
        price: 500,
        icon: SaladIcon,
    },
];

/**
 * Renders the Shop page.
 * @returns {React.JSX.Element} The rendered Shop component.
 */
export default function Shop() {
    return (
        <div className="h-full max-w-xl">
            <div className="flex items-center border-b-2 pb-4">
                <h1 className="flex-1 text-center large-title">
                    Shop
                </h1>
            </div>
            <FeatureRequestModal />
            <div className="mt-10 space-y-4">
                {rewards.map((reward, index) => (
                    <ShopItem key={index} reward={reward} />
                ))}
            </div>
        </div>
    );
}
