import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import DropletIcon from '../../assets/icons/DropletIcon';
import HealthInsights from './HealthInsights';
import { fetchLogCountAPI } from '../../api/healthLogsAPI';
import { useToast } from '../../context/ToastContext';
import ShareWithFriends from './ShareWithFriends';
import Streak from '../../assets/icons/streak.svg';
import SidePanelProgressBar from './Components/SidePanelProgressBar';

/**
 * SidePanel component displays the user's points and health insights.
 * @returns {React.JSX.Element} - SidePanel component
 */
export default function SidePanel() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const { points, fetchPoints, pointsDiff, subscriptionStatus, streak } = useAuth();
    const [logCounts, setLogCounts] = useState({});
    const [dailyPoints, setDailyPoints] = useState(() => {
        const savedPoints = localStorage.getItem('dailyPoints');
        return savedPoints ? parseInt(savedPoints, 10) : 0;
    });

    useEffect(() => {
        const fetchLogCounts = async () => {
            try {
                const data = await fetchLogCountAPI();
                setLogCounts(data);
            } catch (error) {
                addToast({
                    variant: 'error',
                    title: 'Failed to fetch log counts',
                    description: 'Please try again later.',
                });
            }
        };

        fetchLogCounts();
        fetchPoints();
    }, [fetchPoints, addToast]);

    const showHealthInsights = () => {
        return Object.values(logCounts).some((count) => count >= 3);
    };

    return (
        <div className="flex w-full max-w-sm flex-col justify-between text-left">
            <div className="flex min-h-screen w-full flex-col gap-6">

                <div className="mb-10 flex gap-4">
                    <span className="font-bold">Your Stats:</span>
                    <div className="flex items-center gap-1">
                        <p className="font-bold"> {points}</p>
                        <DropletIcon />
                    </div>
                    {streak.current > 0 && (
                        <div className="flex items-center gap-1">
                            <p className="font-bold">{streak.current}</p>
                            <img
                            src={Streak}
                            className="h-4 w-4 mx-0.5 my-px"
                            alt="Wave"
                            />
                        </div>
                    )}
                    {subscriptionStatus === 'active' && (
                        <p className="font-extrabold tracking-wide text-sapphireBlue-600">
                            Infinity
                        </p>
                    )}
                </div>

                <SidePanelProgressBar pointsDiff={pointsDiff} fetchPoints={fetchPoints} />

                <ShareWithFriends />

                <div className="w-full rounded-xl border-2 bg-white px-6 py-6">
                    {showHealthInsights() ? (
                        <HealthInsights />
                    ) : (
                        <>
                            <h1 className="mt-2 small-title tracking-wide">
                                Unlock Health Stats!
                            </h1>
                            <p className="mb-2 mt-2 font-medium text-gray-500">
                                Add 3 or more logs of a specific category to
                                start tracking.
                            </p>
                        </>
                    )}
                </div>

                {subscriptionStatus !== 'active' && (
                    <div className="w-full rounded-xl bg-white px-6 py-2">
                        <h1 className="mt-4 small-title tracking-wide ">
                            Join{' '}
                            <span className="text-sapphireBlue-600">
                                BlueFairy Infinity
                            </span>
                        </h1>
                        <p className="mt-2 font-medium text-gray-500">
                            Get unlimited early access to all the latest
                            features and more.
                        </p>
                        <button
                            type="button"
                            onClick={() => navigate('/checkout')}
                            className="mb-2 mt-6 w-full rounded-2xl bg-sapphireBlue-800 py-4 text-lg font-extrabold text-white transition-all hover:bg-sapphireBlue-600"
                        >
                            Go To Checkout
                        </button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}
