export const GamifiedRadio = ({
    label,
    options,
    required,
    id,
    value,
    onChange,
    className,
    error,
    errorText,
}) => {
    return (
        <div className={`flex flex-col ${className}`}>
            {label && (
                <div className=" flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="text mb-2 block font-bold text-gray-700 md:text-lg"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium error-text">
                            *required
                        </span>
                    ) : (
                        <span className=" rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium success-text">
                            optional
                        </span>
                    )}
                </div>
            )}
            <div className="flex flex-col items-center gap-4">
                {options.map((option) => (
                    <label
                        key={option.value}
                        className="flex w-full cursor-pointer items-center gap-4 rounded-xl border-2 bg-white px-4 py-6 font-extrabold transition-all md:text-lg"
                    >
                        <input
                            type="radio"
                            name={id}
                            value={option.value}
                            checked={value === option.value}
                            onChange={(e) => onChange(e.target.value)}
                            className="h-5 w-5 text-blue-600 transition-colors duration-200 ease-in-out"
                        />
                        <span className="ml-2 text-gray-700">
                            {option.label}
                        </span>
                    </label>
                ))}
            </div>
            {error && (
                <p className="mt-1 small-title error-text">
                    {errorText}
                </p>
            )}
        </div>
    );
};
