import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-5xl font-bold">
                Oh no! The page you are looking for does not exist...
            </h1>
            <Link
                to="/journey"
                className="text-2xl underline text-blue-600 mt-10"
            >
                Head back
            </Link>
        </div>
    );
}

export default NotFound;
