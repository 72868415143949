import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getFieldError } from '../utils/getFieldError';
import { formatPhoneNumber } from '../../../shared/util/formatPhoneNumber';
import GamifiedFieldsArray from './GamifiedFieldsArray';
import cn from '../../ui/utils/cn.jsx';
import { GamifiedStandardInput } from '../../ui/Inputs/GamifiedStandardInput.jsx';
import { GamifiedStandardTextArea } from '../../ui/Inputs/GamifiedStandardTextArea.jsx';
import { GamifiedRadio } from '../../ui/Inputs/GamifiedRadio.jsx';
import { GamifiedStandardSelect } from '../../ui/Inputs/GamifiedStandardSelect.jsx';
import { GamifiedStandardMultiSelect } from '../../ui/Inputs/GamifiedStandardMultiSelect.jsx';
import { GamifiedSingleSelect } from '../../ui/Inputs/GamifiedSingleSelect.jsx';
import { GamifiedMultiSelect } from '../../ui/Inputs/GamifiedMultiSelect.jsx';
import { GamifiedSlider } from '../../ui/Inputs/GamifiedSlider.jsx';
import { GamifiedTimePicker } from '../../ui/Inputs/GamifiedTimePicker.jsx';
import CodeComboBox from '../../ui/Inputs/CodeComboBox';
import { AsyncMultiSelect } from '../../ui/Inputs/AsyncMultiSelect.jsx';
import { GamifiedCheckbox } from '../../ui/Inputs/GamifiedCheckbox.jsx';

/**
 * This is a gamified field component. It renders the appropriate input based on the field type.
 * @returns {React.JSX.Element} The rendered component.
 * @param {object} props - The properties passed to the component.
 * @param {object} props.field - The field object.
 * @param {number} props.fieldIndex - The index of the field.
 * @param {boolean} props.group - The group flag.
 * @param {object} props.defaultValues - The default values.
 */
export default function GamifiedField({ field, fieldIndex, group = false }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const fieldName = `${fieldIndex ? `${fieldIndex}.` : ''}${field.name}`;
    const fieldError = getFieldError(errors, fieldName);

    if (
        field.type === 'text' ||
        field.type === 'date' ||
        field.type === 'email' ||
        field.type === 'number' ||
        field.type === 'tel'
    ) {
        return (
            <div className="w-full px-2">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={''}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedStandardInput
                            type={field.type}
                            required={field.required}
                            id={fieldName}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            reference={ref}
                            placeholder={field.placeholder}
                            label={field.label}
                            error={Boolean(error)}
                            errorText={error?.message}
                            customOnChange={
                                field.type === 'tel' ? formatPhoneNumber : null
                            }
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'textarea') {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    defaultValue={''}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedStandardTextArea
                            id={fieldName}
                            required={field.required}
                            label={field.label}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            reference={ref}
                            error={Boolean(error)}
                            errorText={error?.message}
                            placeholder={field.placeholder}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'radio') {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={field.options[0].value}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <GamifiedRadio
                            label={field.label}
                            id={fieldName}
                            options={field.options}
                            value={value}
                            onChange={onChange}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'comboBox') {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={''}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                    }) => (
                        <CodeComboBox
                            endpoint={field.endpoint}
                            label={field.label}
                            required={field.required}
                            id={fieldName}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'select' && group) {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={field.options[0].value}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedStandardSelect
                            id={fieldName}
                            required={field.required}
                            label={field.label}
                            options={field.options}
                            value={value}
                            onChange={(selectedOption) =>
                                onChange(selectedOption)
                            }
                            onBlur={onBlur}
                            reference={ref}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'multiSelect' && group) {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={[field.options[0].value]}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedStandardMultiSelect
                            id={fieldName}
                            required={field.required}
                            label={field.label}
                            options={field.options}
                            value={value}
                            onChange={(selectedOption) =>
                                onChange(selectedOption)
                            }
                            onBlur={onBlur}
                            reference={ref}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'select') {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={field.options[0].value}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { value, onChange },
                        fieldState: { error },
                    }) => (
                        <GamifiedSingleSelect
                            id={fieldName}
                            required={field.required}
                            title={field.label}
                            options={field.options}
                            value={value}
                            onChange={onChange}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'multiSelect') {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={[]}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <GamifiedMultiSelect
                            id={fieldName}
                            required={field.required}
                            title={field.label}
                            options={field.options}
                            value={value}
                            onChange={onChange}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'asyncMultiSelect') {
        return (
            <div className="w-full">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={[]}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <AsyncMultiSelect
                            id={fieldName}
                            required={field.required}
                            title={field.label}
                            endpoint={field.endpoint}
                            value={value}
                            onChange={onChange}
                            error={Boolean(error)}
                            errorText={error?.message}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'checkbox') {
        return (
            <div className="w-full">
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={false}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedCheckbox
                            label={field.label}
                            id={fieldName}
                            required={field.required}
                            value={value}
                            onChange={onChange}
                            error={error}
                            errorText={error?.message}
                            reference={ref}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'slider') {
        return (
            <div className="w-full">
                <Controller
                    control={control}
                    name={fieldName} // The field name in your form
                    defaultValue={Math.floor((field.min + field.max) / 2)}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                        min: field.min,
                        max: field.max,
                    }}
                    render={({
                        field: { onChange, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedSlider
                            min={field.min}
                            max={field.max}
                            label={field.label}
                            id={fieldName}
                            required={field.required}
                            value={value}
                            onChange={onChange}
                            error={error}
                            errorText={error?.message}
                            reference={ref}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'time') {
        return (
            <div className="w-full">
                <Controller
                    control={control}
                    name={fieldName} // The field name in your form
                    defaultValue={'12:00'}
                    rules={{
                        required: field.required
                            ? 'This field is required'
                            : false,
                    }}
                    render={({
                        field: { onChange, value, ref },
                        fieldState: { error },
                    }) => (
                        <GamifiedTimePicker
                            label={field.label}
                            id={fieldName}
                            required={field.required}
                            value={value}
                            onChange={onChange}
                            error={error}
                            errorText={error?.message}
                            reference={ref}
                        />
                    )}
                />
            </div>
        );
    } else if (field.type === 'dynamic') {
        return (
            <div>
                <GamifiedFieldsArray
                    field={field}
                    fieldIndex={`${fieldIndex ? `${fieldIndex}.` : ''}${
                        field.name
                    }`}
                    fieldError={fieldError}
                />
            </div>
        );
    } else if (field.type === 'group') {
        return (
            <div>
                <h3 className="medium-title">{field.label}</h3>
                <div
                    className={cn(
                        'mt-4 flex items-center',
                        field.subFields.length > 2
                            ? 'flex-col space-y-2'
                            : 'flex-row space-x-4'
                    )}
                >
                    {field.subFields.map((subField) => (
                        <GamifiedField
                            key={`${fieldName}.${subField.name}`}
                            field={subField}
                            fieldIndex={`${fieldName}`}
                            group={true}
                        />
                    ))}
                </div>
            </div>
        );
    }
    return null;
}
