export const submitSleepLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/sleep`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitWaterLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/water`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitMoodLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/mood`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitExerciseLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/exercise`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitFoodLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/food`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitSupplementLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/supplement`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitPainLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/pain`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const submitSymptomLogAPI = async (body) => {
    const response = await fetch(`/api/health-logs/symptom`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const fetchLogsAPI = async (date) => {
    const response = await fetch(`/api/health-logs/${date}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache', // Prevent caching
        },
    });
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    return data;
};

export const fetchAllLogsAPI = async () => {
    const response = await fetch(`/api/health-logs`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache', // Prevent caching
        },
    });
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    return data;
};

export const fetchLogCountAPI = async () => {
    const response = await fetch(`/api/health-logs/count`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to fetch log count');
    const data = await response.json();
    return data;
};

export const fetchMoodInsightsAPI = async () => {
    const response = await fetch(`/api/health-logs/mood/insights`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to fetch mood data');
    const data = await response.json();
    return data;
};

export const fetchWaterInsightsAPI = async () => {
    const response = await fetch(`/api/health-logs/water/insights`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to fetch water data');
    const data = await response.json();
    return data;
};

export const fetchSleepInsightsAPI = async () => {
    const response = await fetch(`/api/health-logs/sleep/insights`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to fetch sleep data');
    const data = await response.json();
    return data;
};

export const fetchPainInsightsAPI = async () => {
    const response = await fetch(`/api/health-logs/pain/insights`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to fetch pain data');
    const data = await response.json();
    return data;
};

export const fetchSymptomInsightsAPI = async () => {
    const response = await fetch(`/api/health-logs/symptom/insights`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to fetch symptom data');
    const data = await response.json();
    return data;
};
