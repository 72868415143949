import { subYears } from 'date-fns';
import * as yup from 'yup';

export const autoimmuneDiseasesSchema = yup.object().shape({
    autoimmuneDiseases: yup
        .array(yup.string())
        .min(1)
        .required('Autoimmune disease status is required'),
});

export const heardAboutAppFromSchema = yup.object().shape({
    heardAboutAppFrom: yup
        .string()
        .required('Source of app discovery is required'),
});

export const reasonForUsingAppSchema = yup.object().shape({
    reasonForUsingApp: yup
        .string()
        .required('Reason for using app is required'),
});

export const knowledgeAboutAutoimmunitySchema = yup.object().shape({
    knowledgeAboutAutoimmunity: yup
        .string()
        .required('Knowledge about autoimmunity is required'),
});

export const appUsageFrequencySchema = yup.object().shape({
    appUsageFrequency: yup.string().required('App usage frequency is required'),
});

export const patientSignupStep1Schema = yup.object().shape({
    firstName: yup.string().min(2).max(100).required('First name is required'),
    lastName: yup.string().min(2).max(100).required('Last name is required'),
    middleName: yup.string().min(2).max(100),
    namePrefix: yup.string().min(2).max(100),
    nameSuffix: yup.string().min(2).max(100),

    phoneNumber: yup
        .string()
        .matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            'Invalid phone number format (e.g., (123) 456-7890).'
        )
        .required('Phone number is required'),
    phoneNumberType: yup
        .string()
        .oneOf(['home', 'work', 'mobile'])
        .required('Phone number type is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
});
export const patientSignupStep2Schema = yup.object().shape({
    username: yup.string().min(3).max(30).required('Username is required'),
    password: yup
        .string()
        .matches(new RegExp('^(?=.*[a-z])'), 'At least one letter is required') // At least one lowercase letter
        .matches(
            new RegExp('^(?=.*[A-Z])'),
            'At least one uppercase letter is required'
        ) // At least one uppercase letter
        .matches(new RegExp('^(?=.*\\d)'), 'At least one digit is required') // At least one digit
        .matches(
            new RegExp('^(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]'),
            'At least one special character is required'
        ) // At least one special character
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    dob: yup
        .date()
        .max(subYears(new Date(), 18), 'You must be at least 18 years old')
        .required('Date of birth is required'),
    termsAgreement: yup.boolean().required('Terms must be agreed to continue'),
    privacyPolicyAgreement: yup
        .boolean()
        .required('Terms must be agreed to continue'),
});
