import React from 'react';
import { useFormContext } from 'react-hook-form';
const autoimmuneDiseases = [
    {
        name: 'Systemic Lupus Erythematosus',
        value: 'SLE',
        image: 'path/to/image.jpg', // Replace with actual image path
    },
    {
        name: 'Multiple Sclerosis (MS)',
        value: 'MS',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Inflammatory Bowel Disease (IBD)',
        value: 'IBD',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Psoriasis',
        value: 'Psoriasis',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Diabetes (Type 1)',
        value: 'Type 1 Diabetes',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Rheumatoid Arthritis (RA)',
        value: 'RA',
        image: 'path/to/image.jpg',
    },
    {
        name: "Graves' Disease",
        value: "Graves' Disease",
        image: 'path/to/image.jpg',
    },
    {
        name: "Addison's Disease",
        value: "Addison's Disease",
        image: 'path/to/image.jpg',
    },
    {
        name: 'Scleroderma',
        value: 'Scleroderma',
        image: 'path/to/image.jpg',
    },
    {
        name: "Hashimoto's Thyroiditis",
        value: "Hashimoto's",
        image: 'path/to/image.jpg',
    },
    {
        name: 'Ankylosing Spondylitis',
        value: 'Ankylosing Spondylitis',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Kawasaki Disease',
        value: 'Kawasaki Disease',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Chronic Illness',
        value: 'Chronic Illness',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Undiagnosed Condition',
        value: 'Undiagnosed Condition',
        image: 'path/to/image.jpg',
    },
    {
        name: 'Other',
        value: 'Other',
        image: 'path/to/image.jpg',
    },
];

/**
 * Renders the AutoimmuneDiseases component.
 * @returns {React.JSX.Element} The rendered AutoimmuneDiseases component.
 */
export default function AutoimmuneDiseases() {
    const { register, watch } = useFormContext();

    // Use watch to get the current value of selected diseases
    const selectedDiseases = watch('autoimmuneDiseases');

    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2 text-center">
                <div className=" flex flex-col items-center justify-center gap-2">
                    <h1 className="medium-title tracking-wide md:text-3xl">
                        My autoimmune journey includes....
                    </h1>
                    <h2>Choose one or more from the following</h2>
                </div>
            </div>
            <div className="mt-10 grid grid-cols-2 gap-4 px-4 md:mt-20 md:grid-cols-4">
                {autoimmuneDiseases.map((item, index) => (
                    <label
                        key={index}
                        className={`h-36 rounded-lg border px-8 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:text-lg ${
                            selectedDiseases.includes(item.value)
                                ? 'bg-pictonBlue-300 hover:bg-pictonBlue-300'
                                : ''
                        }`}
                    >
                        {item.name}
                        <input
                            type="checkbox"
                            value={item.value}
                            {...register('autoimmuneDiseases')}
                            className="hidden" // Hide the checkbox but keep it functional
                        />
                    </label>
                ))}
            </div>
        </>
    );
}
