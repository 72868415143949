export const adminLoginAPI = async (credentials) => {
    const response = await fetch('/api/admin/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to login');
    }

    return response.json();
};

export const fetchProtectedData = async () => {
    const response = await fetch('/api/admin/dashboard', {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to fetch protected data');
    }

    return response.json();
};

export const fetchEarlyAccessUsers = async (page, searchQuery) => {
    const response = await fetch(
        `/api/admin/early-access-users?page=${page}&query=${searchQuery}`
    );
    if (!response.ok) {
        throw new Error('Failed to fetch early access users');
    }
    return response.json();
};

export const fetchAdminUsers = async (page, searchQuery) => {
    const response = await fetch(
        `/api/admin/user?page=${page}&query=${searchQuery}`
    );
    if (!response.ok) {
        throw new Error('Failed to fetch early access users');
    }
    return response.json();
};

export const resetAdminUserPassword = async ({
    password,
    confirmPassword,
    token,
}) => {
    const response = await fetch(`/api/admin/reset-password/${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, confirmPassword }),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch early access users');
    }
    return response.json();
};

export const addAdminUser = async (data) => {
    const response = await fetch(`/api/admin/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch early access users');
    }
    return response.json();
};

export const removeAdminUser = async (id) => {
    const response = await fetch(`/api/admin/user/${id}`, {
        method: 'DELETE',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch early access users');
    }
    return response.json();
};
export const updateAdminUser = async (id, data) => {
    const response = await fetch(`/api/admin/user/${id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch early access users');
    }
    return response.json();
};

export const updateUserApprovalStatus = async (userId, isApproved) => {
    const response = await fetch(`/api/admin/early-access-users/${userId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isApproved }),
    });

    if (!response.ok) {
        throw new Error('Failed to update user approval status');
    }
    return response.json();
};
export const verifyAdminSession = async () => {
    const response = await fetch('/api/admin/verify', {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to verify session');
    }

    return response.json();
};

export const adminLogoutAPI = async () => {
    const response = await fetch('/api/admin/logout', {
        method: 'POST',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to logout');
    }
};
