import LogoWhite from '../../assets/AI4AI_logo_white.png';

export function LogoOnly() {
    return (
        <>
            <img
                src={LogoWhite}
                className="h-10 bg-sapphireBlue-800 rounded-full p-1"
                alt="BlueFairy Logo"
            />
        </>
    );
}

export function LogoFull() {
    return (
        <>
            <LogoOnly />
            <span className="self-center medium-title whitespace-nowrap hidden md:block ">
                BlueFairy
            </span>
        </>
    );
}
