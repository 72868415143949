import React, { useState } from 'react';
import AutoimmuneDiseases from './components/AutoimmuneDiseases';
import ProgressBar from '../../components/ProgressStepper/ProgressBar';
import { IconArrowLeft } from '@tabler/icons-react';
import Interest from './components/Interest';
import Knowledge from './components/Knowledge';
import Frequency from './components/Frequency';
import { FormProvider, useForm } from 'react-hook-form';
import SignUpPatient from './components/SignupPatient';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    appUsageFrequencySchema,
    autoimmuneDiseasesSchema,
    heardAboutAppFromSchema,
    knowledgeAboutAutoimmunitySchema,
    patientSignupStep1Schema,
    patientSignupStep2Schema,
    reasonForUsingAppSchema,
} from './schema';
import { useNavigate } from 'react-router-dom';
import { signUpAPI } from '../../api/userAPI';
import Button from '../../components/ui/Button/Button';
import { DevTool } from '@hookform/devtools';

/**
 * Renders the SignUp page.
 * @returns {React.JSX.Element} The rendered SignUp page.
 */

export default function SignUp() {
    const navigate = useNavigate();
    const [submitError, setSubmitError] = useState();
    const [step, setStep] = useState(1);

    const formSteps = {
        1: autoimmuneDiseasesSchema,
        2: reasonForUsingAppSchema,
        3: knowledgeAboutAutoimmunitySchema,
        4: appUsageFrequencySchema,
        5: patientSignupStep1Schema,
        6: patientSignupStep2Schema,
    };
    const currentSchema = formSteps[step];
    const methods = useForm({
        resolver: yupResolver(currentSchema),
        defaultValues: {
            autoimmuneDiseases: [],
        },
    });
    const {
        handleSubmit,
        formState: { isSubmitting },
        trigger,
        control,
    } = methods;
    const prevStep = () => {
        setStep(step - 1);
    };

    const nextStep = async () => {
        const result = await trigger();
        console.log('triggered');
        if (result) {
            console.log('result', result);
            setStep(step + 1);
        }
    };

    const onSubmit = async (data) => {
        if (step < 6) {
            nextStep();
        } else {
            try {
                const {
                    email,
                    password,
                    confirmPassword,
                    firstName,
                    lastName,
                    middleName,
                    nameSuffix,
                    namePrefix,
                    phoneNumber,
                    phoneNumberType,
                    dob,
                    username,
                    autoimmuneDiseases,
                    appUsageFrequency,
                    reasonForUsingApp,
                    knowledgeAboutAutoimmunity,
                    role,
                    termsAgreement,
                    privacyPolicyAgreement,
                } = data;

                const body = {
                    email: email.toLowerCase(),
                    password,
                    confirmPassword,
                    firstName,
                    lastName,
                    middleName,
                    nameSuffix,
                    namePrefix,
                    phoneNumber,
                    phoneNumberType,
                    dob,
                    username,
                    autoimmuneDiseases,
                    appUsageFrequency,
                    reasonForUsingApp,
                    knowledgeAboutAutoimmunity,
                    heardAboutAppFrom: 'invite',
                    role,
                    termsAgreement,
                    privacyPolicyAgreement,
                };

                await signUpAPI(body);
                localStorage.setItem('email', email.toLowerCase());
                navigate('/verify-otp');
            } catch (error) {
                setSubmitError(
                    error.message || 'Failed to register user. Please try again'
                );
            }
        }
    };

    return (
        <div className="mx-auto min-h-screen max-w-7xl overflow-auto  ">
            <div className="mt-6 flex items-center gap-2 px-4 md:gap-10">
                {step > 1 && (
                    <button
                        onClick={prevStep}
                        className="font-bold text-gray-400"
                    >
                        <IconArrowLeft className=" h-7 " />
                    </button>
                )}
                <ProgressBar currentStep={step} totalSteps={6} />
            </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {step === 1 ? (
                        <AutoimmuneDiseases />
                    ) : step === 2 ? (
                        <Interest />
                    ) : step === 3 ? (
                        <Knowledge />
                    ) : step === 4 ? (
                        <Frequency />
                    ) : (
                        <SignUpPatient step={step} />
                    )}

                    {submitError && (
                        <p className="small-title error-text text-center">
                            {submitError}
                        </p>
                    )}

                    <div className=" mt-10 flex w-full justify-end px-4 md:mt-20">
                        <Button type="submit" disabled={isSubmitting}>
                            {step === 6 ? 'Finish' : 'Next'}
                        </Button>
                    </div>
                </form>
            </FormProvider>
            <DevTool control={control} />
        </div>
    );
}
