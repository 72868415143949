export const feedbackMessages = {
  positiveReinforcement: [
    "Fantastic! You're one step closer to a healthier lifestyle.",
    'Awesome work! Every detail helps us tailor a better experience for you.',
    'Way to go! Your dedication to health is inspiring.',
    "Superb! You're making great progress on your wellness journey.",
    'Excellent! This information is key to unlocking your health potential.',
    "You're doing amazing! Keep up the great work towards a healthier you.",
    "High five! Your efforts today lay the foundation for tomorrow's well-being.",
    'Brilliant! Each step you take brings you closer to your health goals.',
    "You're a star! Thanks for providing such detailed info for your health blueprint.",
    "Impressive! You're navigating your health journey like a pro.",
  ],
  corrections: [
    "Almost there! Let's make a small tweak for accuracy.",
    "You've got this! Just a little adjustment needed.",
    'So close! A slight change will make all the difference.',
    'No worries! Everyone makes typos now and then.',
    'Oops! Looks like we need a bit more info here.',
  ],
  completion: [
    "Congratulations! You've completed this part like a champion.",
    "All set! You're moving full steam ahead on your health journey.",
    'Success! This information will be a game-changer for your health plan.',
    "Finished! You're not just filling forms; you're setting the stage for success.",
    "Victory! You've tackled this like a health hero.",
  ],
  generalEncouragement: [
    'Keep it up! Every bit of info helps us help you better.',
    "You're making waves! This effort today is your triumph tomorrow.",
    'Stay strong! Your health journey is worth every step.',
    'Believe in yourself! Every input brings you closer to your wellness goals.',
    'Your resilience is our inspiration! Together, we can achieve great things.',
  ],
};