import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../context/ToastContext';
import { submitElementAPI, updateElementResponseAPI } from '../../api/unitsAPI';
import GamifiedDynamicForm from '../../components/DynamicForm/GamifiedDynamicForm';
import { formSchema } from './formSchema/formSchema';
import React from 'react';
import useFetchElementConfig from '../../shared/hooks/useFetchElementConfig';
import useFetchElementResponse from '../../shared/hooks/useFetchElementResponse';
import { Loading } from '../../components/Loading/Loading';
/**
 * Renders the ElementForm component.
 * @returns {React.JSX.Element} The rendered ElementForm component.
 */
export default function ElementForm() {
    const navigate = useNavigate();
    const { unitId, elementId } = useParams();
    const { addToast } = useToast();
    const { formResponse, loading } = useFetchElementResponse(elementId);
    const { config } = useFetchElementConfig(unitId, elementId);

    const onFormSubmission = async (data) => {
        console.log(data);
        try {
            if (Object.keys(formResponse).length > 0) {
                await updateElementResponseAPI(elementId, { data });
                addToast({
                    variant: 'success',
                    title: 'Form updated successfully',
                    description: 'You have successfully updated the form.',
                });
                navigate('/profile');
            } else {
                await submitElementAPI(unitId, elementId, { data });
                addToast({
                    variant: 'success',
                    title: 'Form submitted successfully',
                    description: 'You have successfully submitted the form.',
                });
                navigate('/journey');
            }
        } catch (error) {
            addToast({
                variant: 'error',
                title: 'Failed to submit form',
                description: 'Please try again later.',
            });
        }
    };

    if (!config || loading) {
        return <Loading />;
    }

    return (
        <GamifiedDynamicForm
            config={config}
            formSchema={formSchema[config.elementId] || {}}
            totalSteps={config.fields.length || 1}
            onFormSubmission={onFormSubmission}
            defaultValues={formResponse}
        />
    );
}
