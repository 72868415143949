import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import Rocketship from '../../../assets/icons/rocketship.svg';
import React, { useState } from 'react';
import GamifiedModal from '../../../components/DialogModal/GamifiedModal';
import FeatureRequestForm from '../../../components/Forms/FeatureRequestForm';
import Button from '../../../components/ui/Button/Button';

/**
 * Renders the FeatureRequestModal component.
 * @returns {React.JSX.Element} The rendered FeatureRequestModal component.
 */
const FeatureRequestModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>
            <div className="mt-10 flex items-center rounded-2xl border-2 bg-white px-4 py-4">
                <span className="mr-3 text-2xl">
                        <img
                            src={Rocketship}
                            className="h-6 w-6"
                            alt="Rocketship"
                        />
                </span>
                <div>
                    <h2 className="small-title">Request a reward item</h2>
                    <p className="paragraph">
                        We&apos;d love to hear what you&apos;d like to see on
                        the shops page!
                    </p>
                    <div className="flex gap-1"></div>
                </div>
                <Button
                    className="flex gap-1 whitespace-nowrap"
                    onClick={() => setModalIsOpen(true)}
                >
                    Request
                </Button>
            </div>

            <GamifiedModal
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                title={'Feature Request'}
            >
                <FeatureRequestForm setIsOpen={setModalIsOpen} type="Reward" />
            </GamifiedModal>
        </>
    );
};

export default FeatureRequestModal;
