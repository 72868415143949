import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function PointsModal({ isOpen, closeModal, pointsDiff }) {
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 font-sans"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text:md md:text-xl font-bold leading-6 text-gray-900"
                                    >
                                        Congratulations! You just completed{' '}
                                        {pointsDiff === 70
                                            ? 'a Unit'
                                            : 'an Element'}
                                        !
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm md:text-base text-gray-700">
                                            You received {pointsDiff} points!{' '}
                                            {pointsDiff === 70
                                                ? 'Great job on completing a unit. Continue onto the next unit for more points!\n'
                                                : 'Nice work on completing an element. Keep progressing!\n'}
                                            Remember to redeem points in the shop
                                            for gifts.
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm md:text-base font-bold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
