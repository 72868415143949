import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="flex w-full justify-between gap-2 px-4 py-5 font-bold text-gray-500 md:p-10 ">
            <Link
                to="/support"
                className="transition-all hover:text-gray-400 hover:underline"
            >
                Report a bug
            </Link>

            <Link
                to="/terms"
                className="transition-all hover:text-gray-400 hover:underline"
            >
                Terms of Service
            </Link>

            <Link
                to="/privacy"
                className="transition-all hover:text-gray-400 hover:underline"
            >
                Privacy Policy
            </Link>

            <span>© {new Date().getFullYear()} BlueFairy</span>
        </footer>
    );
}

export default Footer;
