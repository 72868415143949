export const raceEnum = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'Other Race',
    'Decline to Answer',
    'Unknown',
];
export const ethnicityEnum = [
    'Hispanic or Latino',
    'Not Hispanic or Latino',
    'Decline to Answer',
    'Unknown',
];

export const sexualOrientationEnum = [
    'Heterosexual',
    'Homosexual',
    'Bisexual',
    'Pansexual',
    'Asexual',
    'Undecided about sexual orientation',
    'Sexual orientation unknown',
    'Concern about sexual orientation',
];

export const genderIdentityEnum = [
    'Male',
    'Female',
    'Transgender Male',
    'Transgender Female',
    'Non-binary',
    'Other',
    'Decline to Answer',
];

export const sexEnum = ['M', 'F', 'UNKNOWN'];

export const FamilyMemberRelationshipEnum = {
    PARENT: 'PRN',
    MOTHER: 'MTH',
    FATHER: 'FTH',
    CHILD: 'CHILD',
    DAUGHTER: 'DAUC',
    SON: 'SONC',
    SPOUSE: 'SPS',
    HUSBAND: 'HUSB',
    WIFE: 'WIFE',
    PARTNER: 'DOMPART',
    SIBLING: 'SIB',
    BROTHER: 'BRO',
    SISTER: 'SIS',
    GRANDPARENT: 'GRPRN',
    GRANDMOTHER: 'GRMTH',
    GRANDFATHER: 'GRFTH',
    GRANDCHILD: 'GRNDCHILD',
    GRANDDAUGHTER: 'GRNDDAU',
    GRANDSON: 'GRNDSON',
    AUNT: 'AUNT',
    UNCLE: 'UNCLE',
    NIECE: 'NIECE',
    NEPHEW: 'NEPHEW',
    COUSIN: 'COUSN',
    ADOPTED_CHILD: 'ADOPTCHILD',
    ADOPTED_DAUGHTER: 'ADOPTDAU',
    ADOPTED_SON: 'ADOPTSON',
    STEPPARENT: 'STPPRN',
    STEPMOTHER: 'STPMTH',
    STEPFATHER: 'STPFTH',
    STEPCHILD: 'STPCHLD',
    STEPDAUGHTER: 'STPDAU',
    STEPSON: 'STPSON',
    FOSTER_PARENT: 'FSTPRN',
    FOSTER_MOTHER: 'FSTMTH',
    FOSTER_FATHER: 'FSTFTH',
    FOSTER_CHILD: 'FSTCHLD',
    FOSTER_DAUGHTER: 'FSTDAU',
    FOSTER_SON: 'FSTSON',
    GUARDIAN: 'GRARPRN',
    HALF_SIBLING: 'HSIB',
    HALF_BROTHER: 'HBRO',
    HALF_SISTER: 'HSIS',
    SIGNIFICANT_OTHER: 'SIGOTHR',
    FRIEND: 'FRND',
    NEIGHBOUR: 'NBOR',
    SELF: 'ONESELF',
    UNKNOWN: 'UNK',
    OTHER: 'OTH',
};

export const conditionCategoryEnum = ['problem-list-item', 'health-concern'];
export const conditionClinicalStatusEnum = [
    'active',
    'recurrence',
    'inactive',
    'relapse',
    'remission',
    'resolved',
];
export const conditionVerificationStatusEnum = [
    'confirmed',
    'unconfirmed',
    'refuted',
    'entered-in-error',
    'provisional',
    'differential',
];

export const conditionSeverityEnum = ['mild', 'moderate', 'severe'];

export const procedureStatusEnum = [
    'not-done',
    'on-hold',
    'stopped',
    'completed',
];

export const doseUnitEnum = ['mg', 'g', 'IU', 'mL'];

export const frequencyEnum = ['hours', 'days', 'weeks', 'months'];
export const adherenceEnum = ['compliant', 'non-compliant', 'not-taking'];
export const medicationStatusEnum = [
    'active',
    'completed',
    'on-hold',
    'cancelled',
    'stopped',
];
export const medicationFillStatusEnum = [
    'dispensed',
    'not-dispensed',
    'partially-dispensed',
];
