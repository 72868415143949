import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitExerciseLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Exercise Log',
    description:
        'Record your exercise activities, including type, duration, and any symptoms experienced.',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
        },
        {
            name: 'exerciseType',
            label: 'Type of Exercise',
            type: 'text',
            required: true,
        },
        {
            name: 'duration',
            label: 'Duration',
            type: 'text',
            required: true,
        },
        {
            name: 'intensity',
            label: 'Intensity',
            type: 'select',
            options: [
                { label: 'Light', value: 'light' },
                { label: 'Moderate', value: 'moderate' },
                { label: 'Vigorous', value: 'vigorous' },
            ],
            required: true,
        },
        {
            name: 'symptomsExperienced',
            label: 'Symptoms Experienced During/After Exercise',
            type: 'textarea',
            required: true,
        },
        {
            name: 'overallFeeling',
            label: 'Overall Feeling Post-Exercise',
            type: 'textarea',
            required: true,
        },
    ],
};

/**
 * ExerciseLog component renders a dynamic form to log exercise activities.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function ExerciseLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitExerciseLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit exercise log. Please try again later.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
        />
    );
}
