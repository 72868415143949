import { feedbackMessages } from "../components/FeedbackMessages";

// Function to randomly select a message from a category
export const getRandomMessage = (category) => {
  const chance =
      category === 'completion' || category === 'corrections'
          ? 1
          : Math.random();
  const messages =
      chance > 0.5
          ? feedbackMessages[category][
                Math.floor(Math.random() * feedbackMessages[category].length)
            ]
          : '';
  return messages;
};