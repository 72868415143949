import {
    allergyFormSchema,
    conditionFormSchema,
    familyHistorySchema,
    medicationFormSchema,
    patientFormSchema,
    procedureFormSchema,
} from './unit1FormSchema';
import {
    currentSymptomsSchema,
    healthMilestonesSchema,
    painManagementSchema,
    previousTreatmentsSchema,
} from './unit2FormSchema';
import {
    dietaryHabitsSchema,
    nutritionalDeficienciesSchema,
    supplementUseSchema,
} from './unit3FormSchema';

export const formSchema = {
    // unit 1
    patient: patientFormSchema,
    allergy: allergyFormSchema,
    familyHistory: familyHistorySchema,
    medication: medicationFormSchema,
    condition: conditionFormSchema,
    procedure: procedureFormSchema,

    currentsymptoms: currentSymptomsSchema,
    painmanagement: painManagementSchema,
    healthmilestones: healthMilestonesSchema,
    previoustreatments: previousTreatmentsSchema,
    dietaryhabits: dietaryHabitsSchema,
    supplementuse: supplementUseSchema,
    nutritionaldeficiencies: nutritionalDeficienciesSchema,
};
