import React, { useState, useEffect, useRef } from 'react';
import { autocompleteAPI } from '../../../api/autocompleteAPI';

/**
 * Renders a combo box input field. Integrates with react-hook-forms controller.
 * @param {object} props The component props.
 * @param {string} props.endpoint The endpoint to fetch options from.
 * @param {string} props.label The label for the input field.
 * @param {string} props.id The id of the input field.
 * @param {boolean} props.required Whether the input field is required.
 * @param {object} props.value The value of the input field.
 * @param {Function} props.onChange The change event handler.
 * @param {Function} props.onBlur The blur event handler.
 * @param {boolean} props.error Whether the input field has an error.
 * @param {string} props.errorText The error text to display.
 * @returns {React.JSX.Element} The rendered ComboBox component.
 */
export default function CodeComboBox({
    endpoint,
    label,
    id,
    value,
    onChange,
    onBlur,
    required,
    error,
    errorText,
}) {
    const [inputValue, setInputValue] = useState(value?.name || value || '');
    const [options, setOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (inputValue) fetchOptions();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [inputValue]);

    const fetchOptions = async () => {
        setLoading(true);
        try {
            const data = await autocompleteAPI(endpoint, inputValue);
            // console.log(data, endpoint);
            setOptions(data);
        } catch (error) {
            console.error('Failed to fetch options:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        setIsOpen(true);
        // Pass the raw input value to React Hook Form
        onChange(newValue);
    };

    const handleSelect = (option) => {
        setInputValue(option.display);
        setIsOpen(false);
        onChange(option); // Pass the full option object to React Hook Form
    };

    const handleBlur = (e) => {
        // Delay closing the dropdown to allow for option selection
        setTimeout(() => {
            setIsOpen(false);
        }, 200);
        onBlur(e);
    };

    return (
        <div className="relative">
            {label && (
                <div className="mt-4 flex items-start gap-2">
                    <label
                        htmlFor={id}
                        className="text mb-2 block font-bold md:text-lg"
                    >
                        {label}
                    </label>
                    {required ? (
                        <span className="error-text rounded-full bg-red-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            *required
                        </span>
                    ) : (
                        <span className=" success-text rounded-full bg-green-100 bg-opacity-50 px-1 py-0.5 font-medium">
                            optional
                        </span>
                    )}
                </div>
            )}
            <input
                ref={inputRef}
                id={id}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onFocus={() => setIsOpen(true)}
                className="w-full appearance-none rounded-xl border-2 px-4 py-2 text-lg leading-tight text-gray-700 shadow md:px-6 md:py-3 md:text-xl"
            />
            {isOpen && (
                <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded border bg-white shadow-lg">
                    {loading ? (
                        <li className="p-2 text-gray-500">Loading...</li>
                    ) : options.length > 0 ? (
                        <>
                            {options.map((option) => (
                                <li
                                    key={option.code}
                                    onClick={() => handleSelect(option)}
                                    className="text mb-2 block cursor-pointer p-2 font-bold hover:bg-gray-100"
                                >
                                    {option.display}
                                </li>
                            ))}
                            <li
                                className="text mb-2 block cursor-pointer p-2 font-bold hover:bg-gray-100"
                                onClick={() => handleSelect(inputValue)}
                            >
                                Proceed with: {inputValue}
                            </li>
                        </>
                    ) : (
                        <li className="p-2 text-gray-500">
                            No options found. You can use your input as a custom
                            value.
                        </li>
                    )}
                </ul>
            )}
            {error && <span className="error-text font-bold">{errorText}</span>}
        </div>
    );
}
