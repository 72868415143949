import React, { useState, useEffect } from 'react';
import PointsProgressBar from '../Components/PointsProgressBar';
import DropletIcon from '../../../assets/icons/DropletIcon';
import { Link } from 'react-router-dom';
import Button from '../../ui/Button/Button';
/**
 * ProgressBar component for the SidePanel
 * @param {object} props - The props that were defined by the parent component
 * @param {number} props.pointsDiff - The difference in points between the previous and current points
 * @param {Function} props.fetchPoints - The function that fetches the user's points
 * @returns {React.JSX.Element} - The JSX code to be rendered
 */
export default function SidePanelProgressBar({ pointsDiff, fetchPoints }) {
    const [dailyPoints, setDailyPoints] = useState(() => {
        const savedPoints = localStorage.getItem('dailyPoints');
        return savedPoints ? parseInt(savedPoints, 10) : 0;
    });

    useEffect(() => {
        const resetDailyPoints = () => {
            //resets daily points to zero at every new calendar day
            const lastReset = localStorage.getItem('lastReset'); // gets date of last reset
            const today = new Date().toISOString().split('T')[0]; //gets just the date of today, minus the "T" time

            if (lastReset !== today) {
                //logic that resets daily pts to 0
                setDailyPoints(0);
                localStorage.setItem('lastReset', today);
                localStorage.setItem('dailyPoints', 0);
            }
        };

        fetchPoints();
        resetDailyPoints();
    }, [fetchPoints]);

    const incrementDailyPoints = (increment) => {
        //updates the dailyPts val by adding increment to itself everytime user gets a newpoint
        setDailyPoints((prevPoints) => {
            const newPoints = prevPoints + increment;
            localStorage.setItem('dailyPoints', newPoints); //updates dailypoints in localstorage as well
            return newPoints;
        });
    };

    useEffect(() => {
        //runs whenever pointsDiff changes, checks if its not null/0, if it has a value it calls incrmntDailyPts
        if (pointsDiff) {
            incrementDailyPoints(pointsDiff);
        }
    }, [pointsDiff]);

    return (
        <div className="mt-4 w-full rounded-xl border-2 bg-white px-6 py-2 pb-6">
            <h1 className="pt-2 small-title tracking-wide">
                Daily Progress
            </h1>
            {dailyPoints >= 50 ? (
                <p className="mb-2 mt-2 font-medium text-gray-500">
                    Good job! You have {dailyPoints} out of 50 points today.
                    You&apos;ve reached your daily goal!
                </p>
            ) : (
                <p className="mb-2 mt-2 font-medium text-gray-500">
                    You&apos;re on track to hit the goal! Continue earning more points.
                </p>
            )}
            <PointsProgressBar
                currentStep={Math.min(dailyPoints, 50)}
                totalSteps={50}
                className=""
            />
            <div className="flex justify-between w-full">
            <div className="mt-4 flex items-center">
                <span className="text-3xl font-semibold">{dailyPoints}/50</span>
                <DropletIcon className="size-7 m-1" />
            </div>
            <Link to="/shop">
                <Button
                    variant="outline"
                    className="w-full mt-4 items-center rounded-xl border-2 bg-white text-gray-700 hover:bg-gray-100">
                        View Rewards
                </Button>
            </Link>
            </div>
        </div>
    );
}

