export const verifySessionAPI = async () => {
    try {
        const response = await fetch('/api/users/validate', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache', // Prevent caching
            },
        });
        if (!response.ok) {
            throw new Error('Session expired. Please log in again.');
        }
    } catch (error) {
        throw new Error('Session verification failed');
    }
};

export const fetchPointsAPI = async () => {
    try {
        const response = await fetch('/api/users/points', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache', // Prevent caching
            },
        });

        if (!response.ok) {
            throw new Error('Unable to fetch points');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Unable to fetch points');
    }
};

export const loginAPI = async (body) => {
    const response = await fetch('/api/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message);
    }
    return data;
};

export const logoutAPI = async () => {
    try {
        await fetch('/api/users/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        throw new Error('Something when wrong logging out.');
    }
};

export const sendOTPEmailAPI = async (email) => {
    const response = await fetch('/api/users/send-otp', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });

    const responseData = await response.json();
    if (!response.ok) {
        throw new Error(responseData.message);
    }
};

export const signUpAPI = async (body) => {
    const response = await fetch('/api/users/signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message);
    }
};

export const fetchUserProgressAPI = async () => {
    try {
        const response = await fetch('/api/users/progress', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache', // Prevent caching
            },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed to get user progress.');
    }
};

export const fetchUserProfileAPI = async () => {
    try {
        const response = await fetch(`/api/users/profile`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache', // Prevent caching
            },
        });
        if (!response.ok) {
            throw new Error('Unable to get profile, try again.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed to fetch profile.');
    }
};

export const requestEarlyAccessAPI = async (data) => {
    const response = await fetch('/api/users/early-access', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const responseBody = await response.json();
        const errorMessage =
            responseBody.message || 'An error occurred. Please try again.';
        throw new Error(errorMessage);
    }
};

export const verifyOTPAPI = async ({ email, otp }) => {
    const response = await fetch('/api/users/verify-otp', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
    });

    const responseData = await response.json();
    if (!response.ok) {
        throw new Error(responseData.message);
    }
    return responseData;
};

export const verifySubscriptionStatusAPI = async () => {
    const response = await fetch('/api/users/subscription-status', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache', // Prevent caching
        },
    });
    const responseBody = await response.json();
    if (!response.ok) {
        const errorMessage =
            responseBody.error || 'An error occurred. Please try again.';
        throw new Error(errorMessage);
    }
    return responseBody;
};

export const forgotPasswordAPI = async (data) => {
    const response = await fetch('/api/users/forgot-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 500) {
        throw new Error('Something went wrong. Please try again.');
    }
};

export const resetPasswordAPI = async (data) => {
    const response = await fetch(`/api/users/reset-password/${data.token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
        throw new Error(responseData.message);
    }
};

export const inviteUserAPI = async (data) => {
    const response = await fetch('/api/users/invite', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok) {
        throw new Error(responseData.message);
    }
};

export const unlockAchievementAPI = async (achievementId) => {
    try {
        const response = await fetch('/api/users/unlock-achievement', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ achievementId }),
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
                errorData.message || 'Failed to unlock achievement'
            );
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchAchievementsAPI = async () => {
    try {
        const response = await fetch('/api/achievements', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache',
            },
        });

        if (!response.ok) {
            throw new Error('Unable to fetch achievements');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Unable to fetch achievements');
    }
};

export const fetchUserAchievementsAPI = async () => {
    try {
        const response = await fetch('/api/users/achievements', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user achievements');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching user achievements:', error.message);
        throw new Error('Unable to fetch user achievements');
    }
};
