import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { bugReportSchema } from './bugReportSchema';
import { sendBugReportAPI } from '../../api/supportAPI';
import { StandardTextArea } from '../../components/ui/Inputs/StandardTextArea';
import { StandardSelect } from '../../components/ui/Inputs/StandardSelect';

/**
 * Renders the SupportPage page.
 * @returns {React.JSX.Element} The rendered SupportPage page.
 */
export default function SupportPage() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
    } = useForm({
        resolver: yupResolver(bugReportSchema),
    });
    const [message, setMessage] = useState('');

    const onSubmit = async (data) => {
        try {
            await sendBugReportAPI(data);
            setMessage(
                'Your report has been submitted. Thank you for helping us improve our service.'
            );
            reset(); // Reset the form after successful submission
        } catch (error) {
            setMessage(error.message);
        }
    };

    return (
        <div className="mx-auto -mt-20 flex min-h-screen max-w-2xl flex-col items-center justify-center gap-10 overflow-auto text-center">
            <div>
                <h1 className="medium-title tracking-wide md:text-4xl">
                    Report a bug
                </h1>
                <p className=" mt-2 tracking-wide md:text-lg">
                    All responses are anonymous. Your feedback helps us identify
                    and fix issues faster. For other inquiries, please contact{' '}
                    <a href="#" className="text-sapphireBlue-600 underline">
                        support@gobeyondlab.in
                    </a>
                </p>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-full text-left"
            >
                <div className="mb-4 w-full">
                    <StandardTextArea
                        label={'Bug Description'}
                        register={register}
                        id={'description'}
                        error={errors?.description}
                        errorText={errors?.description?.message}
                        placeholder="Describe the bug you encountered"
                    />
                </div>

                <div className="mb-4 w-full">
                    <StandardTextArea
                        label={'Steps to Reproduce'}
                        register={register}
                        id={'steps'}
                        error={errors?.steps}
                        errorText={errors?.steps?.message}
                        placeholder="Tell us how to reproduce this bug"
                    />
                </div>

                <div className="mb-4 w-full">
                    <StandardSelect
                        id="severity"
                        label={'Severity of the Issue'}
                        register={register}
                        options={[
                            { value: 'minor', label: 'Minor' },
                            { value: 'moderate', label: 'Moderate' },
                            { value: 'critical', label: 'Critical' },
                        ]}
                        error={errors?.severity}
                        errorText={errors?.severity?.message}
                    />
                </div>

                <div className="mb-4 w-full">
                    <StandardTextArea
                        label={'Additional Comments'}
                        register={register}
                        id={'comments'}
                        error={errors?.comments}
                        errorText={errors?.comments?.message}
                        placeholder="Anything else you'd like us to know?"
                    />
                </div>
                {message && <p className="text-green-500">{message}</p>}

                <button
                    className={`mt-14 rounded-lg border px-4 py-2 text-lg font-extrabold ${
                        isSubmitting
                            ? 'cursor-not-allowed bg-gray-300 text-gray-500'
                            : 'bg-pictonBlue-200 transition-all hover:bg-pictonBlue-300'
                    }`}
                    type="submit"
                    disabled={isSubmitting}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}

SupportPage;
