import * as yup from 'yup';
/**
 * Represents an option in a select field.
 * @typedef {object} Option
 * @property {string} value The value of the option.
 * @property {string} label The label of the option.
 */

/**
 * Represents a subfield or field. Fields can be nested within themselves for dynamic types.
 * @typedef {object} Field
 * @property {string} name The name of the field.
 * @property {string} type The type of the field, e.g., 'text', 'date', 'select', 'dynamic'.
 * @property {string} label The label of the field.
 * @property {boolean} required Indicates if the field is required.
 * @property {string} [placeholder] An optional placeholder for the field.
 * @property {number} [min] The minimum value for the field (applicable for numerical types).
 * @property {number} [max] The maximum value for the field (applicable for numerical types).
 * @property {Option[]} [options] Optional array of options for select fields.
 * @property {Field[]} [subFields] Optional array of subfields for dynamic types.
 */

/**
 * Dynamically generates a Yup validation schema based on a given array of Field objects.
 * Each field's type and validation rules (like required, min, max) are considered
 * to create corresponding Yup validations.
 * @param {Field[]} fields - The array of Field objects to generate the schema for.
 * @returns {yup.ObjectSchema} A Yup object schema built based on the provided fields.
 */
export function generateYupSchema(fields) {
    const schemaFields = fields.reduce((acc, field) => {
        switch (field.type) {
            case 'textarea':
            case 'text':
                acc[field.name] = field.required
                    ? yup.string().required('This field is required')
                    : yup.string();
                break;
            case 'slider':
            case 'number':
                // eslint-disable-next-line no-case-declarations
                let numberSchema = field.required
                    ? yup.number().required('This field is required')
                    : yup.number();
                if (typeof field.min === 'number')
                    numberSchema = numberSchema.min(
                        field.min,
                        `Value should be at least ${field.min}`
                    );
                if (typeof field.max === 'number')
                    numberSchema = numberSchema.max(
                        field.max,
                        `Value should be no more than ${field.max}`
                    );
                acc[field.name] = numberSchema;
                break;
            case 'date':
                acc[field.name] = field.required
                    ? yup.date().required('This field is required')
                    : yup
                          .date()
                          .nullable()
                          .optional()
                          .transform((curr, orig) =>
                              orig === '' ? null : curr
                          );
                break;
            case 'time':
                acc[field.name] = field.required
                    ? yup
                          .string()
                          .required('This field is required')
                          .matches(
                              /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                              'Invalid time format. Use HH:mm'
                          )
                    : yup
                          .string()
                          .matches(
                              /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                              'Invalid time format. Use HH:mm'
                          );
                break;
            case 'multiSelect':
                acc[field.name] = field.required
                    ? yup
                          .array()
                          .of(yup.string())
                          .min(1, 'At least one entry is required')
                          .required('This field is required')
                    : yup.array().of(yup.string());
                break;
            case 'radio':
            case 'checkbox':
            case 'select':
                acc[field.name] = field.required
                    ? yup.string().required('This field is required')
                    : yup.string();
                break;
            case 'dynamic':
                if (field.subFields && field.subFields.length > 0) {
                    acc[field.name] = field.required
                        ? yup
                              .array()
                              .of(generateYupSchema(field.subFields))
                              .min(1, 'At least one entry is required')
                              .required('This field is required')
                        : yup.array().of(generateYupSchema(field.subFields));
                }
                break;
            case 'group':
                if (field.subFields && field.subFields.length > 0) {
                    acc[field.name] = field.required
                        ? yup
                              .object()
                              .shape(generateYupSchema(field.subFields).fields)
                              .required('This field is required')
                        : yup
                              .object()
                              .shape(generateYupSchema(field.subFields).fields);
                }
                break;
            default:
                throw new Error(`Unsupported field type: ${field.type}`);
        }
        return acc;
    }, {});

    return yup.object().shape(schemaFields);
}
