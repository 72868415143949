export const formatDate = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    if (input.length > 8) {
        input = input.slice(0, 8);
    }

    if (input.length >= 3 && input.length <= 4) {
        input = `${input.slice(0, 2)}/${input.slice(2)}`;
    } else if (input.length > 4) {
        input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
    }

    e.target.value = input;
};