export const fetchUnitsAPI = async () => {
    try {
        const response = await fetch('/api/units', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache', // Prevent caching
            },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed to get units.');
    }
};

export const submitElementAPI = async (unitId, elementId, body) => {
    const response = await fetch(`/api/units/${unitId}/elements/${elementId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to submit element. Try again.');
};

export const fetchElementConfigAPI = async (unitId, elementId) => {
    const response = await fetch(`/api/units/${unitId}/elements/${elementId}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Cache-Control': 'no-cache', // Prevent caching
        },
    });
    const data = await response.json();
    if (!response.ok) throw new Error('Failed to fetch element config');
    return data;
};

export const fetchElementResponseAPI = async (elementId) => {
    const response = await fetch(`/api/units/responses/element/${elementId}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Cache-Control': 'no-cache', // Prevent caching
        },
    });
    const data = await response.json();
    return data;
};

export const updateElementResponseAPI = async (elementId, body) => {
    const response = await fetch(`/api/units/responses/element/${elementId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Unable to update element response. Try again.');
};
