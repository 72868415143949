import React from 'react';
import { useNavigate } from 'react-router-dom';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitSleepLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';
import * as Yup from 'yup';

const config = {
    title: 'Sleep Log',
    description: 'Log your sleep for the day',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
        },
        {
            name: 'duration',
            label: 'Duration of Sleep',
            type: 'group',
            placeholder: 'e.g., 8 hours',
            required: true,
            subFields: [
                {
                    name: 'hours',
                    label: 'Hours',
                    type: 'number',
                    placeholder: 'e.g., 8',
                    required: true,
                },
                {
                    name: 'minutes',
                    label: 'Minutes',
                    type: 'number',
                    placeholder: 'e.g., 30',
                    required: false,
                },
            ],
        },
        {
            name: 'quality',
            label: 'Sleep Quality (1-10)',
            type: 'slider',
            required: true,
            min: 1,
            max: 10,
        },
        {
            name: 'sleepTime',
            label: 'Estimated Time of Sleep',
            required: true,
            type: 'time',
        },
        {
            name: 'awakenings',
            label: 'Awakenings During the Night',
            required: true,
            type: 'text',
            placeholder: 'Enter a number or "n/a"',
        },
        {
            name: 'sleepAids',
            required: true,
            label: 'Use of Sleep Aids',
            type: 'multiSelect',
            options: [
                { label: 'Medication', value: 'medication' },
                { label: 'Supplements', value: 'supplements' },
                { label: 'None', value: 'none' },
            ],
        },
        {
            name: 'feelingUponWaking',
            label: 'How did you feel upon waking?',
            required: true,
            type: 'radio',
            options: [
                { label: 'Refreshed', value: 'refreshed' },
                { label: 'Tired', value: 'tired' },
                { label: 'Energized', value: 'energized' },
                { label: 'Groggy', value: 'groggy' },
                { label: 'Other', value: 'other' },
            ],
        },
    ],
};
const sleepLogSchema = Yup.object().shape({
    date: Yup.date().required('Log Date is required'),

    duration: Yup.object().shape({
        hours: Yup.number()
            .required('Hours are required')
            .positive('Hours must be positive')
            .integer('Hours must be an integer')
            .max(24, 'Hours cannot exceed 24'),
        minutes: Yup.number()
            .notRequired()
            .positive('Minutes must be positive')
            .integer('Minutes must be an integer')
            .max(59, 'Minutes cannot exceed 59')
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(true),
    }),

    quality: Yup.number()
        .required('Sleep Quality is required')
        .min(1, 'Minimum quality rating is 1')
        .max(10, 'Maximum quality rating is 10'),

    sleepTime: Yup.string().required('Estimated Time of Sleep is required'),

    awakenings: Yup.string()
        .required('Awakenings during the night is required')
        .test(
            'is-valid-awakening',
            'Enter a valid number or "n/a"',
            (value) => !isNaN(parseInt(value)) || value.toLowerCase() === 'n/a'
        ),

    sleepAids: Yup.array()
        .of(
            Yup.string().oneOf(
                ['medication', 'supplements', 'none'],
                'Invalid sleep aid'
            )
        )
        .required('You must specify any sleep aids used'),

    feelingUponWaking: Yup.string()
        .required('You must specify how you felt upon waking')
        .oneOf(
            ['refreshed', 'tired', 'energized', 'groggy', 'other'],
            'Invalid feeling upon waking'
        ),
});

/**
 * SleepLog component renders a dynamic form to log sleep details.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function SleepLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitSleepLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description: 'Failed to submit sleep log. Please try again.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={sleepLogSchema}
            onFormSubmission={onFormSubmission}
        />
    );
}
