import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';

function CheckoutRedirect({ children }) {
    const {
        isAuthenticated,
        subscriptionStatus,
        verifySession,
        verifySubscriptionStatus,
    } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            await verifySession();
            // only verify Subscription Status if the user is authenticated and is not subscribed
            if (
                isAuthenticated &&
                (subscriptionStatus !== 'active' ||
                    subscriptionStatus !== 'trialing')
            ) {
                await verifySubscriptionStatus();
            }
            setIsLoading(false);
        };
        init();
    }, [verifySession, verifySubscriptionStatus]);

    if (isLoading) {
        return <LoadSpinner />;
    }

    // Redirect subscribed users to '/journey' and keep non-subscribed users on the checkout page
    if (isAuthenticated && subscriptionStatus === 'active') {
        return <Navigate to="/journey" replace />;
    }

    return children; // Render children (the checkout page content) if not redirected
}

export default CheckoutRedirect;
