import { useForm } from 'react-hook-form';
import Button from '../../components/ui/Button/Button';
import { forgotPasswordAPI } from '../../api/userAPI';
import { useToast } from '../../context/ToastContext';
import { StandardInput } from '../../components/ui/Inputs/StandardInput';
import React from 'react';

/**
 * Renders the ForgotPassword page.
 * @returns {React.JSX.Element} The rendered ForgotPassword component.
 */
export default function ForgotPassword() {
    const { addToast } = useToast();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({});

    const forgetPasswordSubmit = async (data) => {
        try {
            await forgotPasswordAPI(data);
            addToast({
                variant: 'success',
                title: 'Success!',
                description:
                    'If your account exists, you should receive an email with the reset link.',
            });
        } catch (error) {
            addToast({
                variant: 'error',
                title: 'Failed to send reset link',
                description: error.message || 'Please try again later',
            });
        }
    };
    return (
        <div className="mx-auto -mt-20 flex min-h-screen max-w-2xl flex-col items-center justify-center gap-10 overflow-auto">
            <div className="mb-20 text-center">
                <h1 className="medium-title tracking-wide md:text-4xl">
                    Forgot Password?
                </h1>
                <p className=" mt-2 tracking-wide md:text-lg">
                    Enter your email below, and if it exists we&apos;ll send a
                    link to reset your password.
                </p>
            </div>
            <form
                onSubmit={handleSubmit(forgetPasswordSubmit)}
                className="w-full max-w-2xl px-4"
            >
                <div className="relative mb-6">
                    <StandardInput
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        error={errors.email}
                        errorText="Invalid email"
                        register={register}
                    />
                </div>
                <div className="mt-10 flex w-full justify-center px-4 md:mt-20">
                    <Button type="submit" disabled={isSubmitting}>
                        Send Link
                    </Button>
                </div>
            </form>
        </div>
    );
}
