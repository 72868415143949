import React from 'react';
import { useFormContext } from 'react-hook-form';

const knowledgeLevels = [
    {
        name: "Don't know much about it",
        icon: 'path/to/beginner-icon.svg', // Replace with actual icon path or component
    },
    {
        name: 'Know the basics',
        icon: 'path/to/intermediate-icon.svg',
    },
    {
        name: 'Well-informed',
        icon: 'path/to/advanced-icon.svg',
    },
    {
        name: 'Very knowledgeable',
        icon: 'path/to/expert-icon.svg',
    },
];

/**
 * Renders the Knowledge component.
 * @returns {React.JSX.Element} The rendered Knowledge component.
 */
export default function Knowledge() {
    const { register, watch } = useFormContext();
    const selectedKnowledge = watch('knowledgeAboutAutoimmunity');
    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2 ">
                <div className=" flex flex-col items-center gap-2 text-center ">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl ">
                        How much do you know about autoimmunity?
                    </h1>
                    <h2>Choose one from the following</h2>
                </div>
            </div>
            <div className="mx-auto mt-20 grid max-w-xl grid-cols-1 gap-4 px-4">
                {knowledgeLevels.map((item, index) => (
                    <label
                        key={index}
                        className={`h-20 rounded-lg border px-8 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:text-lg ${
                            selectedKnowledge === item.name
                                ? 'bg-pictonBlue-300'
                                : ''
                        }`}
                    >
                        {item.name}
                        <input
                            type="radio"
                            value={item.name}
                            {...register('knowledgeAboutAutoimmunity')}
                            className="hidden" // Hide the radio but keep it functional
                        />
                    </label>
                ))}
            </div>
        </>
    );
}
