import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ProgressBar from '../../components/ProgressStepper/ProgressBar';
import { getRandomMessage } from './utils/getRandomMessage';
import { XMarkIcon } from '@heroicons/react/20/solid';
import GamifiedField from './components/GamifiedField';
import { Transition } from '@headlessui/react';
import { FormEndModal } from './components/FormEndModal';
import Button from '../../components/ui/Button/Button';
import { DevTool } from '@hookform/devtools';
import cn from '../ui/utils/cn.jsx';
import ExcitedMascot from '../../assets/mascots/DefaultMascotWithShadow.png';
import ErrorMascot from '../../assets/mascots/ErrorMascot.png';

const GamifiedDynamicForm = ({
    config,
    formSchema,
    totalSteps,
    onFormSubmission,
    defaultValues = undefined,
}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [formError, setFormError] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const formMethods = useForm({
        defaultValues: defaultValues,
    });

    useEffect(() => {
        let timeoutId;
        if (feedback) {
            timeoutId = setTimeout(() => {
                setShowFeedback(false); // Ensures feedback is set to null after timeout
            }, 5000); // Matches transition duration
        }
        return () => clearTimeout(timeoutId); // Clear timeout if component unmounts
    }, [feedback]);

    const validateCurrentStepFields = async (
        field,
        data,
        schema,
        basePath = ''
    ) => {
        let isValid = true; // Assume validation will pass initially.

        const validateField = async (itemData, fieldPath) => {
            try {
                await schema.validateAt(fieldPath, formMethods.getValues());
            } catch (error) {
                if (
                    error.name === 'TypeError' &&
                    error.message.includes('branch is not a function')
                ) {
                    console.error(
                        'Schema resolution error. Check the schema definition for:',
                        fieldPath
                    );
                } else if (error.name === 'ValidationError') {
                    formMethods.setError(fieldPath, {
                        type: 'manual',
                        message: error.errors[0], // Use the first error message for simplicity
                    });
                } else {
                    console.error('Unexpected error during validation:', error);
                }
                isValid = false; // Mark validation as failed.
            }
        };

        if (field.type === 'dynamic') {
            // For dynamic fields, validate each item in the array.
            if (field.required && data[field.name].length === 0) {
                formMethods.setError(`${basePath}${field.name}`, {
                    type: 'manual',
                    message: `At least one ${field.label} is required`,
                });
                isValid = false;
            }

            for (let index = 0; index < data[field.name].length; index++) {
                const item = data[field.name][index]; // Current item in the array for dynamic fields.
                let fieldName = `${basePath}${field.name}[${index}]`;
                validateField(item, fieldName);

                for (let subField of field.subFields) {
                    fieldName = `${basePath}${field.name}[${index}].${subField.name}`;
                    const itemData = item[subField.name];

                    if (
                        subField.type === 'group' ||
                        subField.type === 'dynamic'
                    ) {
                        fieldName = `${basePath}${field.name}[${index}]`;
                        // Recursively handle nested 'group' or 'dynamic' fields.
                        let returnedValue = await validateCurrentStepFields(
                            subField,
                            { [subField.name]: itemData },
                            schema,
                            `${fieldName}.`
                        );
                        if (!returnedValue) {
                            isValid = false;
                        }
                    } else {
                        // Direct validation for standard field types.
                        await validateField(itemData, fieldName);
                    }
                }
            }
        } else if (field.type === 'group') {
            // For group fields, individually validate each subfield.
            for (let subField of field.subFields) {
                const fieldName = `${basePath}${field.name}.${subField.name}`;
                const itemData = data[field.name][subField.name];
                if (subField.type === 'group' || subField.type === 'dynamic') {
                    // Recursively handle nested 'group' or 'dynamic' fields within a group.
                    let returnedValue = await validateCurrentStepFields(
                        subField,
                        { [subField.name]: itemData },
                        schema,
                        `${fieldName}.`
                    );
                    if (!returnedValue) {
                        isValid = false;
                    }
                } else {
                    // Direct validation for standard subfield types within a group.
                    await validateField(itemData, fieldName);
                }
            }
        } else {
            // Direct validation for standard (non-dynamic, non-group) fields.
            const fieldName = `${basePath}${field.name}`;
            const itemData = data[field.name];
            await validateField(itemData, fieldName);
        }

        return isValid;
    };

    const onNext = async () => {
        const formData = formMethods.getValues(); // Get current form data
        console.log(formData);
        const field = config?.fields[currentStep - 1]; // Get the current field configuration
        const isValid = await validateCurrentStepFields(
            field,
            formData,
            formSchema,
            ''
        );
        // Check for errors in the current step
        if (isValid) {
            if (currentStep < totalSteps) {
                setCurrentStep(currentStep + 1);
                setFormError(!isValid);
                setFeedback(getRandomMessage('positiveReinforcement'));
            } else {
                // Handle form submission for the last step
                try {
                    setFormError(!isValid);
                    await onFormSubmission(formData);
                } catch (error) {
                    setShowFeedback(true);
                    setFeedback(
                        'Submission error: Something went wrong. Please try again.'
                    );
                }
            }
        } else {
            setFormError(!isValid);
            setFeedback(getRandomMessage('corrections'));
        }
    };

    const onPrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <FormProvider {...formMethods}>
            <div className="flex h-full flex-col">
                <div className="flex flex-1 flex-col">
                    <div>
                        <h1 className="large-title">{config?.title}</h1>
                        <h2 className="small-title tracking-wide text-gray-500">
                            {config?.description}
                        </h2>
                    </div>

                    <div className="mt-6 flex items-center gap-1 px-4 md:gap-10">
                        <button
                            className="font-bold text-gray-400"
                            type="button"
                            onClick={() => setModalIsOpen(true)}
                        >
                            <XMarkIcon className="h-8 stroke-gray-400 " />
                        </button>
                        <ProgressBar
                            currentStep={currentStep}
                            totalSteps={totalSteps}
                        />
                    </div>

                    <form
                        onSubmit={formMethods.handleSubmit(onNext)}
                        className="min-w-2xl flex h-full w-full flex-1 flex-col items-center justify-center"
                    >
                        <div className="mb-40 mt-10 flex h-full w-full max-w-2xl items-center justify-center ">
                            {config?.fields.map(
                                (field, index) =>
                                    index === currentStep - 1 && (
                                        <div className=" w-full" key={index}>
                                            <GamifiedField field={field} />
                                        </div>
                                    )
                            )}
                        </div>
                        <div className={`fixed bottom-0 z-10 w-full `}>
                            {feedback && (
                                <Transition
                                    appear={true}
                                    show={showFeedback}
                                    enter="transition ease-linear duration-300 transform"
                                    enterFrom="translate-y-10"
                                    enterTo="translate-y-0"
                                    leave="transition ease-linear duration-300 transform"
                                    leaveFrom="translate-y-0"
                                    leaveTo="translate-y-10"
                                    afterLeave={() => setFeedback(null)}
                                >
                                    {/* Place this behind the buttons div using negative margin and z-index */}
                                    <div
                                        className={`absolute inset-x-0 bottom-0 z-0 md:hidden`}
                                    >
                                        <p
                                            className={cn(
                                                'rounded-t-xl border-t-2 py-5 text-center text-xl font-bold bg-white',
                                                feedback && 'bg-green-200',
                                                formError && 'error-text bg-red-200'
                                            )}
                                        >
                                            {feedback}
                                        </p>
                                    </div>
                                </Transition>
                            )}
                            
                            
                            
                            
                            {/* This div should be visually above the feedback, hence a higher z-index */}
                            <div
                                className={cn(
                                    'relative w-full border-2 px-4 py-2 md:py-4 bg-white shadow-md',
                                    feedback && 'bg-green-200',
                                    formError && 'error-text bg-red-200'
                                )}
                            >
                                <div className="mx-auto flex max-w-6xl items-center justify-between">
                                    <Button
                                        type="button"
                                        onClick={onPrevious}
                                        disabled={currentStep < 2}
                                        variant="primary"
                                    >
                                        Prev
                                    </Button>
                                    <div className="flex items-center space-x-4">
                                        <div className="relative hidden md:block">
                                            <div className="container max-w-2xl mx-auto">
                                                <div className={cn(
                                                    'flex items-center justify-end ml-4 transition-all',
                                                    feedback ? 'animate-expand-bounce' : 'w-3/4'
                                                )}>
                                                    <div className={cn(
                                                        'bg-white rounded-xl p-4 w-full text-center text-xl font-bold md:block transition-all duration-200',
                                                        formError && 'error-text'
                                                    )}>
                                                        {feedback}
                                                    </div>
                                                    {feedback &&
                                                        <div className="w-3 overflow-hidden">
                                                            <div className="h-4 bg-white rotate-45 transform origin-top-left rounded-sm drop-shadow shadow-xl"></div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {feedback ?
                                            <img
                                                src={formError ? ErrorMascot : ExcitedMascot}
                                                alt="Mascot"
                                                className="h-16 md:h-24 transition-all mr-4 animate-expand-bounce"
                                            /> :
                                            <div className="h-16 md:h-24"/>
                                        }
                                    </div>
                                    <Button
                                        variant={formError ? 'destructive' : 'primary'}
                                        type="submit"
                                    >
                                        {currentStep < totalSteps ? 'Next' : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            <FormEndModal isOpen={modalIsOpen} closeModal={closeModal} />
            </div>
            <DevTool control={formMethods.control} />
        </FormProvider>
    );
};

export default GamifiedDynamicForm;
