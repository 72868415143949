import React, { Fragment, useContext, useState } from 'react';
import ShopIcon from '../../assets/icons/shop.svg';
import QuestIcon from '../../assets/icons/quest.svg';
import DoorIcon from '../../assets/icons/door.svg';
import JourneyIcon from '../../assets/icons/journey.svg';
import UserIcon from '../../assets/icons/user.svg';
import HeartIcon from '../../assets/icons/hearticon.svg';
import MoreIcon from '../../assets/icons/ellipsishorizontalcircleicon.svg';
import { Link, NavLink } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { AuthContext } from '../../context/AuthContext';
import { LogoFull } from '../Logo/Logo';
import cn from '../ui/utils/cn';
import Button from './components/NavigationButton';
import { useNavigate } from 'react-router-dom';

const navigation = [
    {
        name: 'JOURNEY',
        href: '/journey',
        comingSoon: false,
        icon: JourneyIcon,
    },
    {
        name: 'HEALTH',
        href: '/health',
        comingSoon: false,
        icon: HeartIcon,
    },
    {
        name: 'QUEST',
        href: '/quest',
        comingSoon: true,
        icon: QuestIcon,
    },
    {
        name: 'SHOP',
        href: '/shop',
        comingSoon: false,
        icon: ShopIcon,
    },
    {
        name: 'PROFILE',
        href: '/profile',
        comingSoon: false,
        icon: UserIcon,
    },
];

/**
 * Side navigation bar for the application.
 * @returns {React.JSX.Element} The rendered side navigation bar.
 */
export default function SideNav() {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMoreOpen, setIsMoreOpen] = useState(false);

    const handleMoreClick = () => {
        setIsMoreOpen(!isMoreOpen);
    };

    return (
        <div>
            <aside
                id="logo-sidebar"
                className="fixed left-0 top-0 z-40 hidden h-screen translate-x-0 rounded-tr-2xl border-2 border-r-gray-200 bg-white sm:block md:w-64"
                aria-label="Sidebar"
            >
                <div className="flex h-full flex-col justify-between overflow-y-auto px-4 py-10">
                    <div>
                        <Link
                            to="/"
                            className="mb-10 ml-1 flex items-center gap-2"
                        >
                            <LogoFull />
                        </Link>
                        <ul className="w-full space-y-3 font-medium">
                            {navigation.map((item, index) => (
                                <li className="w-full" key={index}>
                                    <Button
                                        isSelected={
                                            window.location.pathname ===
                                            item.href
                                        }
                                        onClick={() => navigate(item.href)}
                                        className="items-left group flex gap-4 rounded-lg p-2 text-left md:w-full"
                                    >
                                        <img
                                            src={item.icon}
                                            className="h-7 text-gray-500 transition duration-75 group-hover:text-[#777777]"
                                        />
                                        <span className="ms-3 hidden flex-1 font-extrabold tracking-wide md:block">
                                            {item.name}
                                            {item.comingSoon && (
                                                <>
                                                    <br />
                                                    <span className="text-xs">
                                                        (COMING SOON)
                                                    </span>
                                                </>
                                            )}
                                        </span>
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="relative">
                        <div className="relative">
                            <button
                                onClick={handleMoreClick}
                                className={`group flex items-center gap-4 rounded-lg border-2 border-gray-300 bg-white p-2 text-[#777777] shadow-[0_3px_0_0_#d1d5db] hover:-translate-y-1 hover:bg-gray-100 hover:shadow-[0_6px_0_0_#d1d5db] active:translate-y-1 active:shadow-sm md:w-full`}
                            >
                                <span className="sr-only">Open more menu</span>
                                <img
                                    src={MoreIcon}
                                    className="h-7 text-gray-500 transition duration-75 group-hover:text-[#777777]"
                                />
                                <span className="ms-3 hidden whitespace-nowrap font-extrabold tracking-wide md:block">
                                    MORE
                                </span>
                            </button>
                            <Transition
                                show={isMoreOpen}
                                as={Fragment}
                                enter="transition ease-out duration-300"
                                enterFrom="transform opacity-0 translate-y-4"
                                enterTo="transform opacity-100 translate-y-0"
                                leave="transition ease-in duration-300"
                                leaveFrom="transform opacity-100 translate-y-0"
                                leaveTo="transform opacity-0 translate-y-4"
                            >
                                <div
                                    className={`fixed bottom-28 z-40 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-transform ${isMoreOpen ? 'translate-y-0' : 'translate-y-4'}`}
                                >
                                    <Button
                                        onClick={logout}
                                        className="group flex items-center gap-4 rounded-lg p-2 text-[#777777] hover:bg-gray-100"
                                    >
                                        <img
                                            src={DoorIcon}
                                            className="h-7 text-gray-500 transition duration-75 group-hover:text-[#777777]"
                                        />
                                        <span className="hidden whitespace-nowrap text-sm font-extrabold tracking-wide md:block">
                                            LOGOUT
                                        </span>
                                    </Button>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </aside>

            <nav className="fixed bottom-0 left-0 right-0 z-40 border-t-2 border-gray-200 bg-white sm:hidden">
                <ul className="flex items-center justify-evenly p-2">
                    {navigation.map((item, index) => (
                        <li key={index} className="text-center">
                            <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                    cn(
                                        'block p-2',
                                        isActive
                                            ? 'font-extrabold text-blue-500 transition-all'
                                            : 'font-bold text-gray-500'
                                    )
                                }
                            >
                                <img src={item.icon} className="mx-auto h-6" />
                                <span className="flex-1 text-xs tracking-wide">
                                    {item.name}
                                </span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
