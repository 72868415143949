import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

/**
 * The OTP input component with validation.
 * @param {object} props The props for the component.
 * @param {number} props.numberOfDigits The number of digits in the OTP.
 * @param {string} props.email The email address.
 * @returns {React.JSX.Element} The OTP input component.
 */
export default function OtpInputWithValidation({ numberOfDigits, email }) {
    const navigate = useNavigate();
    const { verifyOTP } = useAuth();
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [otpError, setOtpError] = useState(null);
    const otpBoxReference = useRef([]);

    useEffect(() => {
        otpBoxReference.current[0].focus();
    }, []);

    const handleChange = (value, index) => {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    };

    const handleBackspaceAndEnter = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        let data = e.clipboardData
            .getData('text')
            .split('')
            .filter((_, index) => index < numberOfDigits);
        if (data.length === numberOfDigits) {
            setOtp(data);
            otpBoxReference.current[numberOfDigits - 1].focus();
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const otpValue = otp.join('');
            const body = { email, otp: otpValue };
            await verifyOTP(body);
            navigate('/checkout');
        } catch (error) {
            setOtpError(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="mt-10">
            <div className="flex items-center justify-center gap-4">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        onPaste={handlePaste}
                        ref={(el) => (otpBoxReference.current[index] = el)}
                        disabled={isSubmitting}
                        className={`block h-auto w-12 appearance-none rounded-md border border-sapphireBlue-600 p-2 focus:border-2 focus:outline-none md:w-20 md:p-3 md:text-2xl`}
                    />
                ))}
            </div>
            {otpError && (
                <p className="mt-4 small-title error-text">
                    {otpError}
                </p>
            )}
            <button
                className={`mt-14 rounded-lg border px-4 py-2 text-lg font-extrabold ${
                    isSubmitting
                        ? 'cursor-not-allowed bg-gray-300 text-gray-500'
                        : 'bg-pictonBlue-200 transition-all hover:bg-pictonBlue-300'
                }`}
                type="button"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                Submit
            </button>
        </div>
    );
}
