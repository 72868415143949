import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitMoodLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Mood Tracker',
    description:
        'Track your mood, what influences it, and note any correlations with activities or symptoms.',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
        },
        {
            name: 'currentMood',
            label: 'Current Mood',
            type: 'select',
            required: true,
            options: [
                { label: 'Happy', value: 'happy' },
                { label: 'Sad', value: 'sad' },
                { label: 'Anxious', value: 'anxious' },
                { label: 'Angry', value: 'angry' },
                { label: 'Excited', value: 'excited' },
                { label: 'Tired', value: 'tired' },
                { label: 'Stressed', value: 'stressed' },
            ],
        },
        {
            name: 'moodFactors',
            label: 'Factors Influencing Mood',
            required: true,
            type: 'select',
            options: [
                { label: 'Sleep', value: 'sleep' },
                { label: 'Exercise', value: 'exercise' },
                { label: 'Nutrition', value: 'nutrition' },
                { label: 'Weather', value: 'weather' },
                { label: 'Work/School', value: 'work/school' },
                { label: 'Socializing', value: 'socializing' },
                { label: 'Relationship', value: 'relationship' },
                { label: 'Health', value: 'health' },
                { label: 'Other', value: 'other' },
            ],
        },
        {
            name: 'otherFactors',
            type: 'textarea',
            label: 'If Other, please specify',
            placeholder: 'Describe other factors influencing your mood.',
            required: false,
        },
        {
            name: 'correlations',
            label: 'Correlations Noted',
            required: false,
            type: 'textarea',
            placeholder:
                'Note any correlation you’ve noticed with activities, symptoms, or events.',
        },
    ],
};

/**
 * MoodLog component renders a dynamic form to log mood and influencing factors.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function MoodLog() {
    const navigate = useNavigate();
    const addToast = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitMoodLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit mood log. Please try again later.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
        />
    );
}
