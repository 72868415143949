import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button/Button';
import cn from '../../../components/ui/utils/cn';
import React from 'react';

/**
 * The user unit progress component.
 * @param {object} props The component props.
 * @param {object[]} props.data The data.
 * @returns {React.JSX.Element} The user unit progress component.
 */
const UserUnitProgress = ({ data }) => {
    const navigate = useNavigate();
    return (
        <section className="mx-auto mt-10 max-w-4xl rounded-2xl border-2 bg-white p-3 shadow-md">
            {data.length === 0 && (
                <div className="text-center">
                    <h2 className="text-2xl font-bold">Get Started</h2>
                    <p className="text-gray-600">
                        Start your journey by completing the first unit.
                    </p>
                </div>
            )}

            {data.map((unit) => (
                <Disclosure key={unit._id} defaultOpen={false}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button
                                as="div"
                                className="mt-2 flex w-full justify-between rounded-2xl bg-purple-100 p-4 text-left font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                            >
                                <div className="p-2">
                                    <h2 className="flex items-center gap-2 text-xl font-bold">
                                        Unit {unit.order}: {unit.title}
                                        <span
                                            className={cn(
                                                'rounded-full px-2 py-1 text-xs text-white',
                                                unit.isCompleted
                                                    ? 'bg-green-500'
                                                    : 'bg-yellow-500'
                                            )}
                                        >
                                            {unit.isCompleted
                                                ? 'Completed'
                                                : 'In Progress'}
                                        </span>
                                    </h2>
                                </div>
                                <ChevronUpIcon
                                    className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-7 w-7 text-purple-500`}
                                />
                            </Disclosure.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 ">
                                    <ul>
                                        {unit.elements.map((element) => (
                                            <div
                                                key={element._id}
                                                className="flex items-center justify-between gap-2 border-b-2 py-4"
                                            >
                                                <div>
                                                    <h3 className="small-title">
                                                        {element.title}
                                                    </h3>
                                                    <p className="line-clamp-3 paragraph">
                                                        {element.description}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Button
                                                        className="text-xs md:text-base"
                                                        onClick={() =>
                                                            navigate(
                                                                `/unit/${element.unitId}/element/${element.elementId}`
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            ))}
        </section>
    );
};

export default UserUnitProgress;
