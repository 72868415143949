import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitFoodLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Food Log',
    description: 'Log your food intake for the day',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
        },
        {
            name: 'mealType',
            label: 'Meal Type:',
            type: 'select',
            required: true,
            options: [
                { value: 'breakfast', label: 'Breakfast' },
                { value: 'lunch', label: 'Lunch' },
                { value: 'dinner', label: 'Dinner' },
                { value: 'snack', label: 'Snack' },
            ],
        },
        {
            name: 'time',
            label: 'Estimated time of Meal:',
            type: 'time',
            required: true,
        },
        {
            name: 'mealContents',
            label: 'Meal:',
            type: 'dynamic',
            placeholder: 'What did you eat?',
            required: true,
            subFields: [
                {
                    name: 'food',
                    label: 'Food:',
                    type: 'text',
                    placeholder: 'What did you eat?',
                    required: true,
                },
                {
                    name: 'quantity',
                    label: 'Quantity:',
                    type: 'number',
                    placeholder: 'Amount consumed',
                    required: true,
                },
                {
                    name: 'unit',
                    label: 'Unit:',
                    type: 'select',
                    options: [
                        { value: 'g', label: 'g' },
                        { value: 'kg', label: 'kg' },
                        { value: 'ml', label: 'ml' },
                        { value: 'l', label: 'l' },
                        { value: 'cup', label: 'cup' },
                        { value: 'tbsp', label: 'tbsp' },
                        { value: 'tsp', label: 'tsp' },
                        { value: 'oz', label: 'oz' },
                        { value: 'lb', label: 'lb' },
                        { value: 'unit', label: 'unit' },
                        { value: 'serving', label: 'serving' },
                    ],
                    required: true,
                },
            ],
        },
        {
            name: 'reactions',
            label: 'Immediate Reaction:',
            required: true,
            type: 'group',
            subFields: [
                {
                    name: 'digestiveReactions',
                    label: 'Digestive Reaction',
                    type: 'multiSelect',
                    options: [
                        { value: 'normal', label: 'Normal/Satisfied' },
                        { value: 'indigestion', label: 'Indigestion' },
                        { value: 'cramping', label: 'Cramping' },
                        { value: 'gassy', label: 'Gassy' },
                    ],
                    required: true,
                },
                {
                    name: 'physicalReactions',
                    label: 'Physical Reaction',
                    type: 'multiSelect',
                    options: [
                        { value: 'none', label: 'None' },
                        { value: 'bloated', label: 'Bloated' },
                        { value: 'energized', label: 'Energized' },
                        { value: 'fatigued', label: 'Fatigued' },
                        { value: 'stomachache', label: 'Stomachache' },
                        { value: 'heartburn', label: 'Heartburn' },
                    ],
                    required: true,
                },
                {
                    name: 'emotionalResponses',
                    label: 'Emotional Response',
                    type: 'select',
                    options: [
                        { value: 'neutral', label: 'Neutral' },
                        { value: 'happy', label: 'Happy' },
                        { value: 'stressed', label: 'Stressed' },
                        { value: 'satisfied', label: 'Satisfied' },
                        { value: 'disappointed', label: 'Disappointed' },
                        { value: 'guilty', label: 'Guilty' },
                    ],
                    required: true,
                },
                {
                    name: 'otherReactions',
                    label: 'Other Reactions',
                    type: 'textarea',
                    placeholder: 'Please specify any other reactions.',
                    required: false,
                },
            ],
        },
    ],
};

/**
 * FoodLog component renders a dynamic form to log food intake.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function FoodLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitFoodLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit food log. Please try again later.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
        />
    );
}
