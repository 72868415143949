import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitSupplementLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Supplement Log',
    description:
        'Track your supplement intake, including dosage, timing, and any observed effects.',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
        },
        {
            name: 'supplementName',
            label: 'Name of Supplement',
            type: 'text',
            required: true,
        },
        {
            name: 'dosage',
            type: 'group',
            required: true,
            label: 'Dosage',
            subFields: [
                {
                    name: 'doseAmount',
                    type: 'number',
                    required: true,
                    label: 'Amount',
                    placeholder: 'Enter Dose Amount',
                },
                {
                    name: 'doseUnit',
                    type: 'select',
                    label: 'Unit',
                    required: true,
                    options: [
                        { value: 'mg', label: 'mg' },
                        { value: 'g', label: 'g' },
                        { value: 'IU', label: 'IU' },
                        { value: 'mL', label: 'mL' },
                        // Add more units as needed
                    ],
                    placeholder: 'Select Unit',
                },
            ],
        },
        {
            name: 'timeTaken',
            label: 'Time Taken',
            type: 'time',
            required: true,
        },
        {
            name: 'reasonForTaking',
            label: 'Reason for Taking',
            type: 'textarea',
            required: true,
        },
        {
            name: 'observedEffects',
            label: 'Observed Effects/Side Effects',
            type: 'textarea',
            required: true,
        },
    ],
};

/**
 * SupplementLog component renders a dynamic form to log supplement intake.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function SupplementLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitSupplementLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit supplement log. Please try again later.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
        />
    );
}
