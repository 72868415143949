import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Loading } from '../Loading/Loading';

function PrivateRoute({ children }) {
    const { isAuthenticated, subscriptionStatus, verifySession, verifySubscriptionStatus } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            const delay = setTimeout(() => {
                setShowLoader(false);
            },2200); // 2.2 seconds delay

            return () => clearTimeout(delay);
        }
    }, [isLoading]);

    useEffect(() => {
        const checkAccess = async () => {
            await verifySession();
            if (isAuthenticated) {
                await verifySubscriptionStatus();
            }
            setIsLoading(false);
        };
        checkAccess();
    }, [verifySession, verifySubscriptionStatus]);

    if (isLoading || showLoader) {
        return <Loading message/>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (subscriptionStatus !== 'active') {
        return <Navigate to="/checkout" replace />;
    }

    return children;
}

export default PrivateRoute;