/* eslint-disable camelcase */
import LargeStreak from '../../assets/icons/streak.svg';
import Plant from '../../assets/icons/plant.svg';
import Seedling from '../../assets/icons/seedling.svg';
import Lightning from '../../assets/icons/lightning.svg';
import Rainbow from '../../assets/icons/rainbow.svg';
import SmallStreak from '../../assets/icons/smallwave.svg';
import Rain from '../../assets/icons/rain.svg';

const achievementIcons = {
    default: Rainbow,
    first_unit_completed: Seedling,
    completed_an_element: Lightning,
    earn_100_drops: Rain,
    first_streak: SmallStreak,
    completed_3_units: Plant,
    '3_day_streak': LargeStreak,

    // add other mappings as needed
};

export default achievementIcons;
