import React from 'react';
import { useFormContext } from 'react-hook-form';

const frequencyLevels = [
    { name: '5 min' },
    { name: '10 min' },
    { name: '15 min' },
];

/**
 * Renders the Frequency component.
 * @returns {React.JSX.Element} The rendered Frequency component.
 */
export default function Frequency() {
    const { register, watch } = useFormContext();

    const selectedFrequency = watch('appUsageFrequency');

    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2 ">
                <div className=" flex flex-col items-center gap-2 text-center ">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl ">
                        How long do you want to use the app?
                    </h1>
                    <h2>Choose one from the following</h2>
                </div>
            </div>
            <div className="mx-auto mt-10 grid max-w-xl grid-cols-1 gap-4 px-4 md:mt-20">
                {frequencyLevels.map((item, index) => (
                    <label
                        key={index}
                        className={`h-20 rounded-lg border px-8 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:text-lg ${
                            selectedFrequency === item.name
                                ? 'bg-pictonBlue-300'
                                : ''
                        }`}
                    >
                        {item.name}
                        <input
                            type="radio"
                            value={item.name}
                            {...register('appUsageFrequency')}
                            className="hidden" // Hide the radio but keep it functional
                        />
                    </label>
                ))}
            </div>
        </>
    );
}
