import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import LandingPage from './pages/LandingPage/LandingPage';
import NotFound from './pages/NotFound/NotFound';
import SignUp from './pages/Signup/Signup.jsx';
import Journey from './pages/Journey/Journey.jsx';
import Shop from './pages/Shop/Shop.jsx';
import Quest from './pages/Quest/Quest.jsx';
import './App.css';
import { AuthProvider } from './context/AuthContext.jsx';
import OTP from './pages/OTP/OTP.jsx';
import SupportPage from './pages/Support/Support.jsx';
import InviteWarning from './pages/InviteWarning/InviteWarning.jsx';
import ConstructionPage from './pages/Construction/ConstructionPage.jsx';
import { ToastProvider } from './context/ToastContext.jsx';
import ToastContainer from './components/ui/Toast/ToastContainer.jsx';
import FoodLog from './pages/Health/components/healthLogs/FoodLog.jsx';
import ElementForm from './pages/Element/ElementForm.jsx';
import HealthPage from './pages/Health/HealthPage.jsx';
import SleepLog from './pages/Health/components/healthLogs/SleepLog.jsx';
import MoodLog from './pages/Health/components/healthLogs/MoodLog.jsx';
import SupplementLog from './pages/Health/components/healthLogs/SupplementLog.jsx';
import ExerciseLog from './pages/Health/components/healthLogs/ExerciseLog.jsx';
import WaterLog from './pages/Health/components/healthLogs/WaterLog.jsx';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword.jsx';
import ResetPassword from './pages/ResetPassword/ResetPassword.jsx';
import PainLog from './pages/Health/components/healthLogs/PainLog.jsx';
import SymptomLog from './pages/Health/components/healthLogs/SymptomLog.jsx';
import AchievementsPage from './pages/Achievements/Achievements.jsx';
import AdminLogin from './pages/AdminPanel/AdminLogin.jsx';
import AdminPanel from './pages/AdminPanel/AdminPanel.jsx';
import {
    TopNavLayout,
    SideNavLayout,
    LogoOnlyNavLayout,
    Checkout,
    AdminLayout,
} from './Layouts';
import TermsOfService from './pages/TermsOfService/TermsOfService.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.jsx';
import ManageAdminUsers from './pages/AdminPanel/ManageAdminUsers';
import { SocketProvider } from './context/SocketContext.jsx';
import AchievementModal from './components/Achievements/AchievementModal.jsx';
import { AdminAuthProvider } from './context/AdminAuthContext.jsx';
import AdminResetPassword from './pages/AdminPanel/AdminResetPassword';

export const App = () => {
    return (
        <div className="font-sans text-eel">
            <BrowserRouter>
                <AdminAuthProvider>
                    <AuthProvider>
                        <SocketProvider>
                            <ToastProvider>
                                <Routes>
                                    <Route
                                        path="/admin/login"
                                        element={<AdminLogin />}
                                    />
                                    <Route
                                        path="/admin/reset-password/:token"
                                        element={<AdminResetPassword />}
                                    />
                                    <Route element={<AdminLayout />}>
                                        <Route
                                            path="/admin/panel"
                                            element={<AdminPanel />}
                                        />
                                        <Route
                                            path="/admin/manage-users"
                                            element={<ManageAdminUsers />}
                                        />
                                    </Route>

                                    <Route
                                        path="/checkout"
                                        element={<Checkout />}
                                    />
                                    <Route element={<TopNavLayout />}>
                                        <Route
                                            path="/"
                                            element={<LandingPage />}
                                        />
                                        <Route
                                            path="/:inviteCode"
                                            element={<LandingPage />}
                                        />
                                        <Route
                                            path="/invite-warning"
                                            element={<InviteWarning />}
                                        />
                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="/sign-up"
                                            element={<SignUp />}
                                        />
                                        <Route
                                            path="/terms"
                                            element={<TermsOfService />}
                                        />
                                        <Route
                                            path="/privacy"
                                            element={<PrivacyPolicy />}
                                        />
                                        <Route
                                            path="/verify-otp"
                                            element={<OTP />}
                                        />
                                        <Route
                                            path="/forgot-password"
                                            element={<ForgotPassword />}
                                        />
                                        <Route
                                            path="/reset-password/:token"
                                            element={<ResetPassword />}
                                        />
                                        <Route
                                            path="/support"
                                            element={<SupportPage />}
                                        />
                                        <Route
                                            path="*"
                                            element={<NotFound />}
                                        />
                                    </Route>
                                    <Route element={<SideNavLayout />}>
                                        <Route
                                            path="/journey"
                                            element={<Journey />}
                                        />
                                        <Route
                                            path="/profile"
                                            element={<Profile />}
                                        />
                                        <Route
                                            path="/shop"
                                            element={<Shop />}
                                        />
                                        <Route
                                            path="/quest"
                                            element={<Quest />}
                                        />
                                        <Route
                                            path="/health"
                                            element={<HealthPage />}
                                        />
                                        <Route
                                            path="/health"
                                            element={<HealthPage />}
                                        />

                                        <Route
                                            path="/achievements"
                                            element={<AchievementsPage />}
                                        />
                                        <Route
                                            path="/achievements"
                                            element={<AchievementsPage />}
                                        />

                                        <Route
                                            path="/quest/inagene"
                                            element={
                                                <ConstructionPage
                                                    title="Inagene"
                                                    description="Our mission is simple - harness the power of genetic testing and personalized medicine to help people feel better, sooner."
                                                />
                                            }
                                        />
                                        <Route
                                            path="/quest/kalia-health"
                                            element={
                                                <ConstructionPage
                                                    title="Kalia Health"
                                                    description="A biomedical startup focused on enhancing maternal health by targeting pre-eclampsia, a leading cause of maternal mortality that is effectively treatable with early detection. The company has developed an innovative, non-invasive at-home diagnostic test for pre-eclampsia, offering accurate, accessible point-of-care diagnostics to improve outcomes for expectant mothers."
                                                />
                                            }
                                        />
                                        <Route
                                            path="/quest/herhealth"
                                            element={
                                                <ConstructionPage
                                                    title="HERHealth"
                                                    description="A collaborative initiative that strives to empower low-income women working in global supply chains. They are dedicated to building the largest quality data-lake for female patients with an initial focus on supporting endometriosis diagnosis and treatment"
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route element={<LogoOnlyNavLayout />}>
                                        <Route
                                            path="/unit/:unitId/element/:elementId"
                                            element={<ElementForm />}
                                        />
                                        <Route
                                            path="/logs/food"
                                            element={<FoodLog />}
                                        />
                                        <Route
                                            path="/logs/sleep"
                                            element={<SleepLog />}
                                        />
                                        <Route
                                            path="/logs/mood"
                                            element={<MoodLog />}
                                        />

                                        <Route
                                            path="/logs/supplement"
                                            element={<SupplementLog />}
                                        />
                                        <Route
                                            path="/logs/exercise"
                                            element={<ExerciseLog />}
                                        />
                                        <Route
                                            path="/logs/water"
                                            element={<WaterLog />}
                                        />
                                        <Route
                                            path="/logs/pain"
                                            element={<PainLog />}
                                        />
                                        <Route
                                            path="/logs/symptom"
                                            element={<SymptomLog />}
                                        />
                                    </Route>
                                </Routes>
                                <AchievementModal />
                                <ToastContainer />
                            </ToastProvider>
                        </SocketProvider>
                    </AuthProvider>
                </AdminAuthProvider>
            </BrowserRouter>
        </div>
    );
};

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch('/api/admin/verify', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <p>Loading...</p>;
    }

    return isAuthenticated ? children : <Navigate to="/admin/login" />;
};
