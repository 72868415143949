import * as yup from 'yup';
const symptomLocations = [
    { value: 'head', label: 'Head' },
    { value: 'scalp', label: 'Scalp' },
    { value: 'face', label: 'Face' },
    { value: 'eyes', label: 'Eyes' },
    { value: 'ears', label: 'Ears' },
    { value: 'nose', label: 'Nose' },
    { value: 'mouth', label: 'Mouth' },
    { value: 'throat', label: 'Throat' },
    { value: 'neck', label: 'Neck' },
    { value: 'shoulders', label: 'Shoulders' },
    { value: 'chest', label: 'Chest' },
    { value: 'upperBack', label: 'Upper Back' },
    { value: 'lowerBack', label: 'Lower Back' },
    { value: 'abdomen', label: 'Abdomen' },
    { value: 'arms', label: 'Arms' },
    { value: 'elbows', label: 'Elbows' },
    { value: 'wrists', label: 'Wrists' },
    { value: 'hands', label: 'Hands' },
    { value: 'fingers', label: 'Fingers' },
    { value: 'hips', label: 'Hips' },
    { value: 'legs', label: 'Legs' },
    { value: 'knees', label: 'Knees' },
    { value: 'ankles', label: 'Ankles' },
    { value: 'feet', label: 'Feet' },
    { value: 'toes', label: 'Toes' },
    { value: 'skin', label: 'Skin (general)' },
];

const painLocations = [
    { value: 'head', label: 'Head' },
    { value: 'neck', label: 'Neck' },
    { value: 'shoulders', label: 'Shoulders' },
    { value: 'upperArms', label: 'Upper Arms' },
    { value: 'elbows', label: 'Elbows' },
    { value: 'forearms', label: 'Forearms' },
    { value: 'wrists', label: 'Wrists' },
    { value: 'hands', label: 'Hands' },
    { value: 'fingers', label: 'Fingers' },
    { value: 'chest', label: 'Chest' },
    { value: 'upperBack', label: 'Upper Back' },
    { value: 'lowerBack', label: 'Lower Back' },
    { value: 'abdomen', label: 'Abdomen' },
    { value: 'hips', label: 'Hips' },
    { value: 'groin', label: 'Groin' },
    { value: 'buttocks', label: 'Buttocks' },
    { value: 'thighs', label: 'Thighs' },
    { value: 'knees', label: 'Knees' },
    { value: 'shins', label: 'Shins' },
    { value: 'calves', label: 'Calves' },
    { value: 'ankles', label: 'Ankles' },
    { value: 'feet', label: 'Feet' },
    { value: 'toes', label: 'Toes' },
];

const timeDurationUnits = [
    { value: 'seconds', label: 'Seconds' },
    { value: 'minutes', label: 'Minutes' },
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' },
];
const symptomSchema = yup.object().shape({
    symptomDescription: yup
        .string()
        .required('Symptom Description is required'),
    symptomLocation: yup
        .array()
        .of(
            yup.string().oneOf(
                symptomLocations.map((item) => item.value),
                'Invalid Symptom Location selected'
            )
        )
        .required('Symptom Location is required'),
    severity: yup
        .number()
        .typeError('Amount must be a number')
        .typeError('Amount must be a number')
        .min(1, 'Severity must be at least 1')
        .max(10, 'Severity cannot be more than 10')
        .required('Severity is required'),
    duration: yup.object().shape({
        durationAmount: yup
            .number()
            .typeError('Amount must be a number')
            .required('Duration Amount is required'),
        durationUnit: yup
            .string()
            .oneOf(
                timeDurationUnits.map((item) => item.value),
                'Invalid Duration Unit selected'
            )
            .required('Duration Unit is required'),
    }),
    frequency: yup.object().shape({
        frequencyAmount: yup
            .number()
            .typeError('Amount must be a number')
            .required('Frequency Amount is required'),
        frequencyUnit: yup
            .string()
            .oneOf(
                ['daily', 'weekly', 'monthly', 'yearly', 'occasionally'],
                'Invalid Frequency Unit selected'
            )
            .required('Frequency Unit is required'),
    }),
    triggers: yup.string().required('Triggers are required'),
    reliefMethods: yup.string().required('Relief Methods are required'),
});

export const currentSymptomsSchema = yup.object().shape({
    symptom: yup.array().of(symptomSchema),
});

const painSchema = yup.object().shape({
    painLocations: yup
        .array()
        .of(
            yup.string().oneOf(
                painLocations.map((item) => item.value),
                'Invalid Pain Location selected'
            )
        )
        .required('Pain Locations are required'),
    painSeverity: yup
        .number()
        .typeError('Amount must be a number')
        .min(1, 'Pain Severity must be at least 1')
        .max(10, 'Pain Severity cannot be more than 10')
        .required('Pain Severity Over Time is required'),
    painCharacteristics: yup
        .string()
        .oneOf(
            ['sharp', 'dull', 'throbbing', 'constant', 'intermittent'],
            'Invalid Pain Characteristics selected'
        )
        .required('Pain Characteristics are required'),
    effectivePainRelief: yup
        .string()
        .required('Effective Pain Relief Techniques are required'),
    painImpact: yup
        .number()
        .typeError('Amount must be a number')
        .min(1, 'Pain Impact must be at least 1')
        .max(10, 'Pain Impact cannot be more than 10')
        .required('Pain Impact on Daily Activities is required'),
});

export const painManagementSchema = yup.object().shape({
    pain: yup.array().of(painSchema),
});

const healthMilestoneSchema = yup.object().shape({
    milestoneType: yup
        .string()
        .oneOf(
            ['diagnosis', 'surgery', 'other'],
            'Invalid Type of Milestone selected'
        )
        .required('Type of Milestone is required'),
    milestoneDate: yup.date().required('Date is required'),
    milestoneDescription: yup.string().required('Description is required'),
    milestoneOutcome: yup.string().required('Outcome is required'),
});

export const healthMilestonesSchema = yup.object().shape({
    healthMilestone: yup
        .array()
        .of(healthMilestoneSchema)
        .required('At least one Health Milestone is required'),
});

const treatmentSchema = yup.object().shape({
    treatmentType: yup
        .string()
        .oneOf(
            ['medication', 'therapy', 'surgery', 'other'],
            'Invalid Treatment Type selected'
        )
        .required('Treatment Type is required'),
    treatmentName: yup.string().required('Treatment Name is required'),
    treatmentDates: yup.object().shape({
        startDate: yup.date().required('Start Date is required'),
        endDate: yup.date().required('End Date is required'),
    }),
    dose: yup.object().shape({
        doseAmount: yup
            .number()
            .typeError('Amount must be a number')
            .required('Dose Amount is required'),
        doseUnit: yup
            .string()
            .oneOf(['mg', 'g', 'IU', 'mL'], 'Invalid Dose Unit selected')
            .required('Dose Unit is required'),
    }),
    dosageDetails: yup.string().required('Dosage/Details are required'),
    treatmentResponse: yup
        .string()
        .oneOf(['positive', 'neutral', 'negative'], 'Invalid Response selected')
        .required('Response is required'),
    sideEffects: yup.string().required('Side Effects are required'),
});

export const previousTreatmentsSchema = yup.object().shape({
    treatment: yup
        .array()
        .of(treatmentSchema)
        .required('At least one Treatment is required'),
});
