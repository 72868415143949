import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Unit from './components/Unit';
import PointsModal from './components/PointsModal';
import FeatureRequestModal from '../../components/DialogModal/FeatureRequestModal';
import useFetchUnits from '../../shared/hooks/useFetchUnits';
import useFetchProgress from '../../shared/hooks/useFetchProgress';

/**
 * Renders the Journey Page that displays the units and elements.
 * @returns {React.JSX.Element} The rendered Journey component.
 */
export default function Journey() {
    const { pointsDiff, setPointsDiff } = useContext(AuthContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const units = useFetchUnits();
    const progress = useFetchProgress();

    useEffect(() => {
        if (pointsDiff > 0) {
            setModalIsOpen(true);
        }
    }, [pointsDiff]);

    const closeModal = () => {
        setModalIsOpen(false);
        setPointsDiff(0);
    };

    return (
        <div className="w-full max-w-xl">
            <div className="flex items-center border-b-2 pb-4">
                <h1 className="flex-1 ml-12 text-center large-title">
                    Your Health Journey
                </h1>
                <FeatureRequestModal type="Unit" />
            </div>
            {units.map((unit, index) => (
                <Unit
                    key={unit._id}
                    unit={unit}
                    index={index}
                    progress={progress}
                />
            ))}
            <div className="my-20 md:my-40">
                <h2 className="small-title text-gray-500 text-center">
                    Don&apos;t worry! More opportunities for points are on the
                    way!
                </h2>
            </div>
            <PointsModal
                isOpen={modalIsOpen}
                closeModal={closeModal}
                pointsDiff={pointsDiff}
            />
        </div>
    );
}
